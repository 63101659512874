import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as z from "zod";

import actions from "@/actions";
import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { FormLabel, FormRow, InputText, TextArea, Select, FormError } from "@/sx-layout/common/Form";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "@/sx-layout/common/Modal";
import {
  KeepOutAreaFormValues,
  CreateKeepOutAreaParams,
  UpdateKeepOutAreaParams,
} from "@/sx-layout/components/plotmap/actions/types";
import { KeepOutArea, Machine, MachineStatusLabel, UserRole } from "@/sx-layout/components/plotmap/models";
import { formatUseHour, formatUseHourString } from "@/sx-layout/components/plotmap/util/formatUseHour";
import { User } from "@/sx-layout/models";
import { HHMMRegex } from "@/sx-layout/utils";

const INITIAL_KEEP_OUT_AREA_SIZE = 100; //新規作成時・紐づきエリア作成時の縦横サイズ

export type KeepOutAreaEditProps = {
  readonly keepOutAreaData?: KeepOutArea;
  readonly plotPlanId: number;
  readonly onClose: (res?: any) => void;
  readonly initialX: number;
  readonly initialY: number;
  readonly withMachine: Machine;
};

export const KeepOutAreaEdit: FC<KeepOutAreaEditProps> = ({
  keepOutAreaData,
  plotPlanId,
  onClose,
  initialX,
  initialY,
  withMachine = undefined,
}) => {
  const { t } = useTranslation();
  const userRole = useSelector((state) => state.layoutApp.layoutMasters.user_role);
  const isAdmin = userRole === UserRole.MASTER_ROLE;

  const formSchema = z
    .object({
      // Adminならcompanyの選択を必須にする
      company_id: z.union([z.number(), z.null()]).refine((value) => !isAdmin || value !== null, {
        message: t("select_company"),
      }),
      // 1文字以上100文字未満
      work_content: z
        .string()
        .min(1, { message: t("input_content_validation") })
        .max(100, { message: t("content_is_too_long_100") }),
      // 必須かつ日付の形式 HH:MM を満たしているか
      use_start_hour: z
        .string()
        .refine((hour) => hour !== "", { message: `${t("operation_time")}：${t("input_start_hour_validation")}` })
        .refine((hour) => HHMMRegex.test(hour) && hour !== "24:00", {
          message: `${t("operation_time")}：${t("error_start_hour")}`,
        }),
      // 必須かつ日付の形式 HH:MM を満たしているか
      use_end_hour: z
        .string()
        .refine((hour) => hour !== "", { message: `${t("operation_time")}：${t("input_end_hour_validation")}` })
        .refine((hour) => HHMMRegex.test(hour) && hour !== "24:00", {
          message: `${t("operation_time")}：${t("error_end_hour")}`,
        }),
      // user_id 必須
      user_id: z.union([z.number(), z.null()]).refine((value) => value !== null, {
        message: t("input_machine_user_validation"),
      }),
      // 100文字未満 (空可)
      note: z.string().max(100, { message: t("note_is_too_long_100") }),
    })
    // EndHour >= StartHour
    .refine((data) => formatUseHour(data.use_start_hour) < formatUseHour(data.use_end_hour), {
      message: t("work_time_alert"),
      path: ["use_end_hour"],
    }) satisfies z.ZodType<KeepOutAreaFormValues>;

  const mode: "create" | "update" = keepOutAreaData?.keepout_area_id ? "update" : "create";

  const dispatch = useDispatch();

  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);

  const layoutMasterUsers = useSelector((state) => state.layoutApp.layoutMasters.users);
  const companies = useSelector((state) => state.layoutApp.layoutMasters.companies);

  const loginUserId = useSelector((state) => state.session.userId);
  const loginUser = layoutMasterUsers.find((user) => user.user_id === loginUserId);
  const loginCompany = companies?.find((c) => c.company_id === loginUser.company_id);
  const initCompanyId = keepOutAreaData?.company_id ?? withMachine?.company_id ?? loginCompany.company_id;

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<KeepOutAreaFormValues>({
    resolver: zodResolver(formSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      company_id: initCompanyId,
      use_start_hour: withMachine?.use_start_hour ?? keepOutAreaData?.use_start_hour,
      use_end_hour: withMachine?.use_end_hour ?? keepOutAreaData?.use_end_hour,
      work_content: withMachine?.work_content ?? keepOutAreaData?.work_content,
      user_id: withMachine?.user_id ?? keepOutAreaData?.user_id ?? null,
      note: keepOutAreaData?.note,
    },
  });

  // 時間文字列のフォーマット
  const useStartHour = watch("use_start_hour");
  const onBlurUseStartHour = useCallback(() => {
    setValue("use_start_hour", formatUseHourString(useStartHour));
  }, [useStartHour]);
  const useEndHour = watch("use_end_hour");
  const onBlurUseEndHour = useCallback(() => {
    setValue("use_end_hour", formatUseHourString(useEndHour));
  }, [useEndHour]);
  const keepOutAreaUser = layoutMasterUsers.find((user) => {
    const user_id = withMachine?.user_id ?? keepOutAreaData?.user_id;
    return user.user_id === user_id;
  });

  const getCompanyUsers = (companyId: number): User[] => {
    return layoutMasterUsers?.filter((u) => u.company_id === companyId);
  };

  const getCompanyName = (companyId: number): string => {
    return companies.find((c) => c.company_id === companyId)?.company_name ?? "-----";
  };

  const [users, setUsers] = useState(() => getCompanyUsers(initCompanyId));

  const [phoneNumber, setPhoneNumber] = useState(keepOutAreaUser?.phone_number ?? "-----");

  const [companyName, setCompanyName] = useState(getCompanyName(keepOutAreaUser?.company_id));

  // 会社の選択肢に連動して使用者を表示する
  const onChangeCompany = (companyId: number) => {
    setUsers(getCompanyUsers(companyId));
    setValue("user_id", null);
    setCompanyName("-----");
    setPhoneNumber("-----");
  };

  // 使用者の選択肢に連動して所属・連絡先を表示する
  const onChangeUser = (userId: number) => {
    const user = layoutMasterUsers.find((user) => user.user_id === userId);
    setPhoneNumber(user?.phone_number ?? "-----");
    setCompanyName(getCompanyName(user?.company_id));
  };

  const createKeepOutAreas = (formValues: KeepOutAreaFormValues) => {
    // 登録時は表示中のMAP中央.
    let params: CreateKeepOutAreaParams = {
      ...formValues,
      machine: withMachine
        ? {
            machine_id: withMachine.machine_id,
            timestamp: {
              update_date: withMachine.timestamp.update_date,
            },
          }
        : null,
      plot_plan_id: plotPlanId,
      layout_date: moment(layoutDate).format("YYYY-MM-DD").toString(),
      x: initialX,
      y: initialY,
      w: INITIAL_KEEP_OUT_AREA_SIZE,
      h: INITIAL_KEEP_OUT_AREA_SIZE,
    };

    // 重機に紐づく場合は重機の座標
    if (withMachine) {
      params = {
        ...params,
        x: withMachine.x - INITIAL_KEEP_OUT_AREA_SIZE / 2,
        y: withMachine.y - INITIAL_KEEP_OUT_AREA_SIZE / 2,
        use_start_hour: null,
        use_end_hour: null,
        work_content: null,
        user_id: null,
      };
    }
    dispatch(actions.keepOutAreas.createKeepOutAreas({ params, onSuccess: onClose }));
  };

  const updateKeepOutAreas = (formValues: KeepOutAreaFormValues) => {
    const params: UpdateKeepOutAreaParams = {
      ...formValues,
      keepout_area_id: keepOutAreaData.keepout_area_id,
      timestamp: keepOutAreaData.timestamp,
    };
    dispatch(actions.keepOutAreas.updateKeepOutAreas({ params, onSuccess: onClose }));
  };

  const onSubmit = (values: KeepOutAreaFormValues) => {
    if (mode === "create") {
      createKeepOutAreas(values);
    } else {
      updateKeepOutAreas(values);
    }
  };

  return (
    <Modal
      onClose={onClose}
      id="keep-out-areas-editor-modal"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <ModalHeader>{t("edit_keep_out_area")}</ModalHeader>
      <ModalBody className="modal-body items-start">
        <form>
          {!withMachine && isAdmin && (
            <FormRow errorComponent={errors.company_id && <FormError>{errors.company_id.message}</FormError>}>
              <FormLabel>{t("company_of_machine_owner")}</FormLabel>
              {mode === "create" ? (
                <Select
                  className="w-200"
                  name="company_id"
                  // @ts-ignore
                  control={control}
                  handleChange={onChangeCompany}
                  options={companies.map((c) => ({
                    label: c.company_name,
                    value: c.company_id,
                  }))}
                />
              ) : (
                <span className="w-[160px] px-[8px] ">{companyName}</span>
              )}
            </FormRow>
          )}

          {/* 重機 */}
          {withMachine && withMachine.machine_type_id && (
            <FormRow>
              <FormLabel>{t("heavy_machine")}</FormLabel>
              <span className="w-[160px] px-[8px] ">{withMachine.machine_type_name}</span>
            </FormRow>
          )}

          <FormRow
            errorComponent={
              <>
                {errors.use_start_hour && <FormError>{errors.use_start_hour.message}</FormError>}
                {errors.use_end_hour && <FormError>{errors.use_end_hour.message}</FormError>}
              </>
            }
          >
            <FormLabel>{t("operation_time")}</FormLabel>
            {withMachine ? (
              <>
                <span className="pl-[8px]">{withMachine.use_start_hour}</span>
                <span className="pl-[8px]">〜</span>
                <span className="pl-[8px]">{withMachine.use_end_hour}</span>
              </>
            ) : (
              <>
                <InputText
                  type="text"
                  className="w-[80px]"
                  {...register("use_start_hour")}
                  onBlur={onBlurUseStartHour}
                />
                <span className="mx-3">〜</span>
                <InputText
                  type="text"
                  className="w-[80px]"
                  {...register("use_end_hour", { deps: ["use_start_hour"] })}
                  onBlur={onBlurUseEndHour}
                />
              </>
            )}
          </FormRow>
          <FormRow
            className="items-start"
            errorComponent={errors.work_content && <FormError>{errors.work_content.message}</FormError>}
          >
            <FormLabel className={withMachine ? "" : "pt-[6px]"}>{t("content")}</FormLabel>
            {withMachine ? (
              <span className="w-[300px] px-[8px] ">{withMachine.work_content}</span>
            ) : (
              <InputText type="text" className="w-[300px]" {...register("work_content")} />
            )}
          </FormRow>

          <FormRow>
            <FormLabel>{t("belonging")}</FormLabel>
            <span className="w-[300px] px-[8px] ">{companyName}</span>
          </FormRow>

          <FormRow errorComponent={errors.user_id && <FormError>{errors.user_id.message}</FormError>}>
            <FormLabel>{t("machine_user")}</FormLabel>
            {withMachine ? (
              <span className="w-[160px] px-[8px]">{withMachine.charge_user_name}</span>
            ) : (
              <Select
                className="w-200"
                name="user_id"
                // @ts-ignore
                control={control}
                handleChange={onChangeUser}
                isClearable={true}
                options={users.map((user) => ({
                  label: user.user_name,
                  value: user.user_id,
                }))}
              />
            )}
          </FormRow>

          <FormRow>
            <FormLabel>{t("contact")}</FormLabel>
            <span className="w-[160px] px-[8px] ">{phoneNumber}</span>
          </FormRow>

          {/* 稼働状況 */}
          {withMachine && withMachine.status && (
            <FormRow>
              <FormLabel>{t("machine_status")}</FormLabel>
              <span className="w-[160px] px-[8px]">{t(MachineStatusLabel[withMachine.status])}</span>
            </FormRow>
          )}

          <FormRow className="items-start" errorComponent={errors.note && <FormError>{errors.note.message}</FormError>}>
            <FormLabel className="mt-[4px]">{t("note")}</FormLabel>
            <TextArea rows={5} {...register("note")} />
          </FormRow>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button buttonType="cancel" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button buttonType="save" onClick={handleSubmit(onSubmit)}>
          {t("save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
