import * as constants from "@/constants";
import environments from "@/environments";
import {
  CreatePlotPlanProps,
  DeletePlotPlanProps,
  FetchPlotPlanProps,
  FetchPlotPlansProps,
  UpdatePlotPlanProps,
} from "@/sx-layout/components/settings/plotPlanSetting/actions/types";

export const fetchPlotPlans = (props?: FetchPlotPlansProps) => {
  const endpoint = `${environments.API_BASE_URI}/plot_plans`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_PLAN_BEGIN_FETCH_PLOT_PLANS,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.PLOT_PLAN_END_FETCH_PLOT_PLANS,
          payload: response.plot_plans,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchPlotPlan = (props?: FetchPlotPlanProps) => {
  const endpoint = `${environments.API_BASE_URI}/plot_plans/${props.input.plot_plan_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_PLAN_BEGIN_FETCH_PLOT_PLAN,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.PLOT_PLAN_END_FETCH_PLOT_PLAN,
          payload: response.plot_plans,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const createPlotPlan = (props: CreatePlotPlanProps) => {
  const formData = new FormData();
  formData.append("quality", String(props.input.quality));
  formData.append("plot_plan_name", String(props.input.plot_plan_name));
  formData.append("note", String(props.input.note));
  formData.append("uploadfile", props.input.uploadfile);

  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/plot_plans/create`,
    method: "POST",
    body: formData,
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_PLAN_BEGIN_CREATE_PLOT_PLAN,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.PLOT_PLAN_END_CREATE_PLOT_PLAN,
          payload: response,
        };
      },
      error: (response) => {
        if (props?.onFailure !== undefined) {
          props.onFailure();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updatePlotPlan = (props: UpdatePlotPlanProps) => {
  const { plot_plan_id, ...restInput } = props.input;

  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/plot_plans/update/${plot_plan_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(restInput),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_PLAN_BEGIN_UPDATE_PLOT_PLAN,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.PLOT_PLAN_END_UPDATE_PLOT_PLAN,
          payload: response,
        };
      },
      error: (response) => {
        if (props?.onFailure !== undefined) {
          props.onFailure();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deletePlotPlan = (props: DeletePlotPlanProps) => {
  const { plot_plan_id } = props.input;

  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/plot_plans/delete/${plot_plan_id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({}),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_PLAN_BEGIN_DELETE_PLOT_PLAN,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.PLOT_PLAN_END_DELETE_PLOT_PLAN,
          payload: response,
        };
      },
      error: (response) => {
        console.log("error!!!");

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
