import Konva from "konva";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Moveable from "react-moveable";
import { useDispatch, useSelector } from "react-redux";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { TooltipKeepOutArea } from "./TooltipKeepOutArea";
import { TooltipMachine } from "./TooltipMachine";
import { FreeDrawMain } from "./freeDraw/components/FreeDrawMain";

import actions from "@/actions";
import { getConstructionId, getUserId } from "@/lib/common";
import storageManager from "@/lib/storageManager";
import { RootState } from "@/reducers/types";
import { Header } from "@/sx-layout/common/Header";
import { DeleteConfirmModal } from "@/sx-layout/common/Modal";
import { HelpModal } from "@/sx-layout/common/Modal/HelpModal";
import {
  UpdateKeepOutAreasCoordinatesProps,
  UpdateMachineCoordinatesProps,
  UpdateMachineKeepOutAreasCoordinatesProps,
} from "@/sx-layout/components/plotmap/actions/types";
import { ChangeMachineUseEndHourModal } from "@/sx-layout/components/plotmap/components/ChangeMachineUseEndHourModal";
import { CopyMachineKeepOutAreaModal } from "@/sx-layout/components/plotmap/components/CopyMachineKeepOutAreaModal";
import { DeleteKeepOutAreaModal } from "@/sx-layout/components/plotmap/components/DeleteKeepOutAreaModal";
import { DeleteMachineModal } from "@/sx-layout/components/plotmap/components/DeleteMachineModal";
import { KeepOutAreaEdit } from "@/sx-layout/components/plotmap/components/KeepOutAreaEdit";
import { KeepOutAreaObject } from "@/sx-layout/components/plotmap/components/KeepOutAreaObject";
import { KeepOutAreaSubMenu } from "@/sx-layout/components/plotmap/components/KeepOutAreaSubMenu";
import { Loading } from "@/sx-layout/components/plotmap/components/Loading";
import { MachineEditor } from "@/sx-layout/components/plotmap/components/MachineEditor";
import { MachineObject } from "@/sx-layout/components/plotmap/components/MachineObject";
import { MachineSubMenu } from "@/sx-layout/components/plotmap/components/MachineSubMenu";
import { PlotPlanSelector } from "@/sx-layout/components/plotmap/components/PlotPlanSelector";
import { ArrowDrawStage } from "@/sx-layout/components/plotmap/components/freeDraw/components/ArrowDrawStage";
import { CopyFreeDrawModal } from "@/sx-layout/components/plotmap/components/freeDraw/components/CopyFreeDrawModal";
import { EditFreeDrawArrowModal } from "@/sx-layout/components/plotmap/components/freeDraw/components/EditFreeDrawArrowModal";
import { EditFreeDrawTextModal } from "@/sx-layout/components/plotmap/components/freeDraw/components/EditFreeDrawTextModal";
import {
  useDrawingArrow,
  useSelectedArrow,
  useSelectedText,
} from "@/sx-layout/components/plotmap/components/freeDraw/hooks";
import { FreeDrawArrow, FreeDrawText } from "@/sx-layout/components/plotmap/components/freeDraw/models";
import { MachineList, MachineListHeader } from "@/sx-layout/components/plotmap/components/machineList";
import { NavigationToolbar, Toolbar } from "@/sx-layout/components/plotmap/components/toolbar";
import {
  useChangeMachineUseEndHourModal,
  useCopyModal,
  useModal,
  useNavigation,
} from "@/sx-layout/components/plotmap/hooks";
import { KeepOutArea, Machine, MachineStatus, UserRole, UserRoles } from "@/sx-layout/components/plotmap/models";
import { ASSOCIATED_COPY } from "@/sx-layout/components/plotmap/util/constants";
import { formatUseHour } from "@/sx-layout/components/plotmap/util/formatUseHour";
import { PlotPlan } from "@/sx-layout/components/settings/plotPlanSetting/models";
import { useTerm } from "@/sx-layout/hooks";
import { Company, Role, User } from "@/sx-layout/models";

const REVIEW_MAP_HEIGHT = 214;
const REVIEW_MAP_WIDTH = 300;
const IMAGE_BASE_WIDTH = 2000;
const MAX_IMAGE_SIZE = 5000; // 500%の時の画像サイズ
const HALF_KEEP_OUT_AREA_SIZE = 50; //新規作成時の立入禁止エリアの座標調整（100x100pxで作成するのでその半分　→　立入禁止エリアは左上が起点となるため）
const JUKI_ICON_SIZE = 40;

Konva.pixelRatio = (window.devicePixelRatio ?? 1) * 2; // 自由描画の品質：大きいほど高くなる

export const PlotMap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const users = useSelector<any, User[]>((state) => state.layoutApp.layoutMasters.users);
  const loggedInUserCompanyId = useMemo(() => {
    const currentLoggedInUserId = getUserId();
    const company = users.find((user) => user.user_id === currentLoggedInUserId);
    if (!company) {
      return undefined;
    }
    return company.company_id;
  }, [users]);
  const companies = useSelector<any, Company[]>((state) => state.layoutApp.layoutMasters.companies);
  const loggedInUserCompanyIconColor = useMemo(() => {
    if (!companies || companies.length === 0 || !loggedInUserCompanyId) return undefined;
    const loggedInUserCompany = companies.find((company) => company.company_id === loggedInUserCompanyId);
    return loggedInUserCompany?.icon_color ? `#${loggedInUserCompany.icon_color}` : undefined;
  }, [companies, loggedInUserCompanyId]);

  const constructionId = getConstructionId();

  const plotPlanId = useSelector<RootState, string>((state) => state.plotmap.plotPlanId);
  const plotplan = useSelector<RootState, PlotPlan>((state) => {
    const { plotPlans } = state.plotPlan;
    return plotPlans.find((plan) => String(plan.plot_plan_id) == plotPlanId);
  });

  const mapImageBlob = useSelector<RootState, Blob>((state) => state.plotmap.mapImage);
  const fetching = useSelector<RootState, boolean>(
    (state) => state.plotPlan.fetching || state.plotmap.fetching || state.machine.fetching
  );
  const mapImage = useMemo(() => {
    return mapImageBlob && URL.createObjectURL(mapImageBlob);
  }, [mapImageBlob]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!plotPlanId || (typeof plotPlanId === "string" && plotPlanId === "undefined")) {
      setIsLoading(false);

      return;
    }

    if (!mapImage || fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [fetching, mapImage, plotPlanId]);

  // Plot map states
  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);
  const prevLayoutDateRef = useRef(layoutDate);

  const machines = useSelector<RootState, Machine[]>((state) => state.plotmap.machines);
  const keepoutAreas = useSelector<RootState, KeepOutArea[]>((state) => state.plotmap.keepoutAreas);
  const arrows = useSelector<RootState, FreeDrawArrow[]>((state) => state.plotmap.arrows);
  const texts = useSelector<RootState, FreeDrawText[]>((state) => state.plotmap.texts);

  // Navigation
  const navigation = useNavigation();

  // Modal
  const machineEditorModal = useModal();
  const keepOutAreaEditorModal = useModal();
  const copyModal = useCopyModal();
  const deleteMachineModal = useModal();
  const deleteAreaModal = useModal();
  const changeMachineUseEndHourModal = useChangeMachineUseEndHourModal();
  const helpModal = useModal();
  const plotPlanSelector = useModal();
  const editArrowModal = useModal();
  const deleteArrowModal = useModal();
  const copyArrowModal = useModal();
  const editTextModal = useModal();
  const copyTextModal = useModal();
  const deleteTextModal = useModal();

  // Plot map objects
  const [selectedMachine, setSelectedMachine] = useState<Machine>(null);
  const selectedMachineRef = useRef<HTMLDivElement>();
  const machineMoveableRef = useRef<HTMLDivElement>();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const [selectedArea, setSelectedArea] = useState<KeepOutArea>();
  const [withMachine, setWithMachine] = useState<Machine>();
  const selectedAreaRef = useRef<HTMLDivElement>();
  const areaMoveableRef = useRef<HTMLDivElement>();

  const [drawScreenElem, setDrawScreenElem] = useState<HTMLDivElement>();

  const drawingArrow = useDrawingArrow({ defaultColor: loggedInUserCompanyIconColor });
  const selectedArrow = useSelectedArrow();
  const selectedText = useSelectedText();

  // Privileges
  const userRole = useSelector<any, UserRoles>((state) => state.layoutApp.layoutMasters.user_role);
  const isAdmin = useMemo(() => userRole === UserRole.MASTER_ROLE, [userRole]);
  const isUser = useMemo(() => userRole === UserRole.USER_ROLE, [userRole]);
  const layoutRoles = useSelector<RootState, Role>((state) => state.app.roles.layout?.layout);
  useEffect(() => {
    navigation.setEditable(layoutRoles?.update);
  }, [layoutRoles]);
  const isMachineFromOtherCompany = useMemo(() => {
    if (!selectedMachine) {
      return false;
    }
    if (!loggedInUserCompanyId) {
      return false;
    }
    return selectedMachine.company_id !== loggedInUserCompanyId;
  }, [selectedMachine, loggedInUserCompanyId]);
  const isAreaFromOtherCompany = useMemo(() => {
    if (!selectedArea) {
      return false;
    }
    if (!loggedInUserCompanyId) {
      return false;
    }
    return selectedArea.company_id !== loggedInUserCompanyId;
  }, [selectedArea, loggedInUserCompanyId]);
  const { isPast, isToday } = useTerm(layoutDate);

  // Component refs
  const contentRef = useRef(null);
  const menuRef = useRef(null);
  const operationRef = useRef(null);
  const tableRef = useRef(null);
  const mapRef = useRef(null);
  const transformRef = useRef(null);
  const imageRef = useRef(null);

  // Map control
  const [isDragging, setIsDragging] = React.useState(false);
  const resizeTarget = useRef<{ top: number; left: number; width: number; height: number }>();
  const [minScale, setMinScale] = useState(0.5);
  const [maxScale, setMaxScale] = useState(5);
  const [mapHeight, setMapHeight] = useState<number>();
  const [reviewMap, setReviewMap] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  // 横幅2000を基準にした時の各配置箇所の比率の設定(但し2000px => 200%)
  const scale = transformRef.current ? 1 / transformRef.current.state.scale : 1;
  const [mapRatio, setMapRatio] = useState(1);
  const [magnificationRatio, setMagnificationRatio] = useState(Number(storageManager.getLayoutMapMagnification()));

  useEffect(() => {
    // 配置日が変更されてなければ更新をスキップ
    if (prevLayoutDateRef.current === layoutDate || fetching) {
      return;
    }
    prevLayoutDateRef.current = layoutDate;

    // setShouldTooltipRender(false);
    // setTimeout(() => setShouldTooltipRender(true), 200);
  }, [layoutDate, fetching]);

  // 排他エラーで再読み込みしたらモーダルを閉じる
  useEffect(() => {
    if (selectedMachine && (machineEditorModal.isOpen || deleteMachineModal.isOpen)) {
      const machine = machines.find((v) => v.machine_id === selectedMachine.machine_id);
      if (machine?.timestamp?.update_date !== selectedMachine.timestamp.update_date) {
        machineEditorModal.close();
        deleteMachineModal.close();
        setSelectedMachine(null);
        selectedMachineRef.current = null;
      }
    }
  }, [machines, selectedMachine, machineEditorModal.isOpen, deleteMachineModal.isOpen]);

  useEffect(() => {
    if (withMachine && keepOutAreaEditorModal.isOpen) {
      const machine = machines.find((v) => v.machine_id === withMachine.machine_id);
      if (machine?.timestamp?.update_date !== withMachine.timestamp.update_date) {
        keepOutAreaEditorModal.close();
        setWithMachine(null);
        setSelectedMachine(null);
        selectedMachineRef.current = null;
      }
    }
  }, [machines, withMachine, keepOutAreaEditorModal.isOpen]);

  useEffect(() => {
    if (selectedArea && (keepOutAreaEditorModal.isOpen || deleteAreaModal.isOpen)) {
      const area = keepoutAreas.find((v) => v.keepout_area_id === selectedArea.keepout_area_id);
      if (area?.timestamp?.update_date !== selectedArea.timestamp.update_date) {
        keepOutAreaEditorModal.close();
        deleteAreaModal.close();
        setSelectedArea(null);
        setWithMachine(null);
        selectedAreaRef.current = null;
      }
    }
  }, [keepoutAreas, selectedArea, keepOutAreaEditorModal.isOpen, deleteAreaModal.isOpen]);

  useEffect(() => {
    if (selectedArrow.freeDrawArrow && (editArrowModal.isOpen || deleteArrowModal.isOpen)) {
      const arrow = arrows.find((v) => v.arrow_id === selectedArrow.freeDrawArrow.arrow_id);
      if (arrow?.timestamp?.update_date !== selectedArrow.freeDrawArrow.timestamp.update_date) {
        editArrowModal.close();
        deleteArrowModal.close();
        selectedArrow.end();
      }
    }
  }, [arrows, selectedArrow.freeDrawArrow, editArrowModal.isOpen, deleteArrowModal.isOpen]);

  useEffect(() => {
    if (selectedText.freeDrawText && (editTextModal.isOpen || deleteTextModal.isOpen)) {
      const text = texts.find((v) => v.freetext_id === selectedText.freeDrawText.freetext_id);
      if (text?.timestamp?.update_date !== selectedText.freeDrawText.timestamp.update_date) {
        editTextModal.close();
        deleteTextModal.close();
        selectedText.end();
      }
    }
  }, [texts, selectedText.freeDrawText, editTextModal.isOpen, deleteTextModal.isOpen]);

  useEffect(() => {
    const handleResizeMap = () => {
      if (mapImage && imageRef.current) {
        setMapRatio(imageRef.current.offsetWidth / IMAGE_BASE_WIDTH);
        setMaxScale(MAX_IMAGE_SIZE / imageRef.current.offsetWidth);

        const magnificationRatio = (imageRef.current.offsetWidth / IMAGE_BASE_WIDTH / scale) * 100 * 2;
        setMagnificationRatio(magnificationRatio);
        storageManager.setLayoutMapMagnification(magnificationRatio);
      }
    };

    handleResizeMap();
    window.addEventListener("resize", handleResizeMap);

    return () => {
      window.removeEventListener("resize", handleResizeMap);
    };
  }, [mapImage, imageRef.current]);

  useEffect(() => {
    setMagnificationRatio((mapRatio / scale) * 2 * 100);
  }, [mapRatio, scale]);

  useEffect(() => {
    const resizeMapHeight = () => {
      if (contentRef.current && menuRef.current && operationRef.current && tableRef.current) {
        setMapHeight(
          contentRef.current.offsetHeight -
            menuRef.current.offsetHeight -
            operationRef.current.offsetHeight -
            tableRef.current.offsetHeight
        );
      }
    };

    resizeMapHeight();
    window.addEventListener("resize", resizeMapHeight);

    return () => {
      window.removeEventListener("resize", resizeMapHeight);
    };
  }, [navigation.showMachineList, machines.length]);

  useEffect(() => {
    if (plotplan?.map_image_id) {
      dispatch(actions.plotmap.fetchMapImage(plotplan.map_image_id));
    }
  }, [plotplan?.map_image_id]);

  useEffect(() => {
    if (plotPlanId) {
      dispatch(actions.plotmap.fetchMachines(Number(plotPlanId), layoutDate));
      dispatch(actions.plotmap.fetchKeepOutAreas(Number(plotPlanId), layoutDate));
      dispatch(actions.plotmap.fetchArrows(Number(plotPlanId), layoutDate));
      dispatch(actions.plotmap.fetchTexts(Number(plotPlanId), layoutDate));
    } else {
      if (constructionId) {
        plotPlanSelector.open();
      } else {
        plotPlanSelector.close();
      }
    }
  }, [plotPlanId, layoutDate, constructionId]);

  useEffect(() => {
    updateNavigationArea();
  }, [mapHeight]);

  const isAnyModalOpen =
    machineEditorModal.isOpen ||
    keepOutAreaEditorModal.isOpen ||
    copyModal.target ||
    deleteMachineModal.isOpen ||
    deleteAreaModal.isOpen ||
    changeMachineUseEndHourModal.isOpen ||
    editArrowModal.isOpen ||
    deleteArrowModal.isOpen ||
    copyArrowModal.isOpen ||
    helpModal.isOpen;

  const handleClick = useCallback(
    (event) => {
      if (isAnyModalOpen) {
        return;
      }
      const paths = event.composedPath();

      // 重機ツールチップの添付ファイルのダウンロードリンクのクリック時は、プレビューとダウンロードダイアログの表示のため、制御する
      if (
        paths.some((path: HTMLElement) => path.id === "tooltip-machine") &&
        paths.some((path: HTMLElement) => path.tagName === "A")
      ) {
        return;
      }

      // 重機ツールチップの添付ファイルの画像プレビューを閉じた場合であっても、ツールチップは表示したままにする
      if (
        paths.some((path: HTMLElement) => {
          if (typeof path.className === "string") {
            return path.className.includes("react-images");
          }

          return false;
        })
      ) {
        return;
      }

      if (
        paths.every((path: HTMLElement) => {
          return String(path.id).indexOf("area-") !== 0;
        })
      ) {
        setKeepOutAreaSubMenuRef(null);
        setSelectedArea(null);
        setWithMachine(null);
        areaMoveableRef.current = null;
      }

      if (
        paths.every((path: HTMLElement) => {
          return String(path.id).indexOf("machine-") !== 0;
        })
      ) {
        setMachineSubMenuRef(null);
        setSelectedMachine(null);
        machineMoveableRef.current = null;
      }
    },
    [isAnyModalOpen, selectedArea]
  );

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    if (constructionId) {
      dispatch(actions.machine.fetchMachineTypes());
      dispatch(actions.plotPlan.fetchPlotPlans());
    }
    storageManager.setUserItem("system", "layout");
  }, []);

  // 拡大縮小時のメニューのサイズ調整
  useEffect(() => {
    if (document.querySelector("head #rpt-container-style")) {
      document.querySelector("head #rpt-container-style").remove();
    }
    const style = `<style id="rpt-container-style"> .rpt-container { transform: scale(${scale}) }<style>`;
    document.querySelector("head").insertAdjacentHTML("beforeend", style);
  }, [scale]);

  const [initialPosition, setInitialPosition] = useState({
    machine: {
      initialX: 0,
      initialY: 0,
    },
    keepOutArea: {
      initialX: 0,
      initialY: 0,
    },
  });

  useEffect(() => {
    if (imageRef.current && mapRef.current) {
      setInitialPosition({
        machine: {
          initialX: Math.round(
            (-imageRef.current.getBoundingClientRect().x +
              mapRef.current.getBoundingClientRect().x +
              mapRef.current.getBoundingClientRect().width / 2) /
              (imageRef.current.getBoundingClientRect().width / IMAGE_BASE_WIDTH)
          ),
          initialY: Math.round(
            (-imageRef.current.getBoundingClientRect().y +
              mapRef.current.getBoundingClientRect().y +
              mapRef.current.getBoundingClientRect().height / 2) /
              (imageRef.current.getBoundingClientRect().width / IMAGE_BASE_WIDTH)
          ),
        },
        keepOutArea: {
          initialX:
            Math.round(
              (-imageRef.current.getBoundingClientRect().x +
                mapRef.current.getBoundingClientRect().x +
                mapRef.current.getBoundingClientRect().width / 2) /
                (imageRef.current.getBoundingClientRect().width / IMAGE_BASE_WIDTH)
            ) - HALF_KEEP_OUT_AREA_SIZE,
          initialY:
            Math.round(
              (-imageRef.current.getBoundingClientRect().y +
                mapRef.current.getBoundingClientRect().y +
                mapRef.current.getBoundingClientRect().height / 2) /
                (imageRef.current.getBoundingClientRect().width / IMAGE_BASE_WIDTH)
            ) - HALF_KEEP_OUT_AREA_SIZE,
        },
      });
    }
  }, [reviewMap]);

  const updateNavigationArea = () => {
    if (transformRef.current && mapRef.current && imageRef.current) {
      const mapClientRect = mapRef.current.getBoundingClientRect();
      const imageClientRect = imageRef.current.getBoundingClientRect();
      if (mapClientRect.width !== 0 && imageClientRect.width !== 0) {
        const startPosition = transformRef.current.state;
        const widthRatio = mapClientRect.width / imageClientRect.width;
        const heightRatio = REVIEW_MAP_WIDTH / mapClientRect.width;
        const reviewMapWidth = REVIEW_MAP_WIDTH * widthRatio;
        const { positionX, positionY, previousScale, scale } = transformRef.current.state;

        // NOTE: モーダルを表示している状態でリサイズした場合は選択中のオブジェクトをクリアしないこと
        if (!isAnyModalOpen) {
          setSelectedMachine(null);
          setSelectedArea(null);
          setWithMachine(null);
          setKeepOutAreaSubMenuRef(null);
          setMachineSubMenuRef(null);
          areaMoveableRef.current = null;
          machineMoveableRef.current = null;
        }

        if (reviewMapWidth > REVIEW_MAP_WIDTH) {
          setReviewMap({
            top: (startPosition.positionY * -heightRatio) / scale - 2,
            left: -2,
            width: REVIEW_MAP_WIDTH,
            height: mapClientRect.height * (reviewMapWidth / mapClientRect.width) + 2,
          });
          if (previousScale > scale) {
            transformRef.current.centerView();
          }
        } else {
          const top = (positionY * -heightRatio) / scale - 2;
          const left = (positionX * -reviewMapWidth) / (imageClientRect.width * widthRatio) - 2;
          const height = mapClientRect.height * (reviewMapWidth / mapClientRect.width) + 2;

          setReviewMap({
            top,
            left,
            width: reviewMapWidth,
            height,
          });
        }
      }
    }
  };

  const handleImageLoaded = () => {
    if (mapRef.current && transformRef.current && imageRef.current) {
      const mapClientRect = mapRef.current.getBoundingClientRect();
      const imageClientRect = imageRef.current.getBoundingClientRect();
      setMinScale(mapClientRect.height / imageClientRect.height);
      updateNavigationArea();
    }
  };

  const handleZoomIn = () => {
    transformRef.current.zoomIn(0.2, 200);
    setTimeout(() => {
      updateNavigationArea();
    }, 200);
  };

  const handleZoomOut = () => {
    transformRef.current.zoomOut(0.2, 200);
    setTimeout(() => {
      updateNavigationArea();
    }, 200);
  };

  const zoomToElement = (no: number) => {
    if (transformRef.current) {
      const { zoomToElement, state } = transformRef.current;
      zoomToElement(`machine-${no}`, state.scale, 300);
      setTimeout(() => {
        updateNavigationArea();
      }, 300);
    }
  };

  const jukiTooltipStyle = (id): "left-aligned" | "right-aligned" => {
    const transformWrapper = transformRef.current?.state;
    const displayAreaRect = mapRef.current?.getBoundingClientRect();
    const juki = document.querySelector(`#machine-${id}`) as HTMLDivElement;
    const mapCenter = mapRef.current?.getBoundingClientRect?.().width / 2;

    if (transformWrapper && displayAreaRect && juki) {
      if (juki.getBoundingClientRect()?.left + JUKI_ICON_SIZE / 2 >= mapCenter) {
        return "left-aligned";
      } else {
        return "right-aligned";
      }
    }

    return "left-aligned";
  };

  const areaTooltipStyle = (id): "left-aligned" | "right-aligned" => {
    const transformWrapper = transformRef.current?.state;
    const displayAreaRect = mapRef.current?.getBoundingClientRect();
    const area = document.querySelector(`#area-${id}`) as HTMLDivElement;
    const mapCenter = mapRef.current?.getBoundingClientRect?.().width / 2;

    if (transformWrapper && displayAreaRect && area) {
      const areaCenter = area.getBoundingClientRect()?.left + selectedArea.w / 2;
      if (areaCenter >= mapCenter) {
        return "left-aligned";
      } else {
        return "right-aligned";
      }
    }

    return "left-aligned";
  };

  const [machineSubMenuRef, setMachineSubMenuRef] = useState(null);
  const [keepOutAreaSubMenuRef, setKeepOutAreaSubMenuRef] = useState(null);

  const getTranslateXY = (element) => {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  };

  // 紐付き
  const updateMachineKeepOutAreasCoordinates = (props: UpdateMachineKeepOutAreasCoordinatesProps) => {
    dispatch(actions.machineEdit.updateMachineKeepOutAreasCoordinates(props));
  };

  const updateMachineCoordinates = (props: UpdateMachineCoordinatesProps) => {
    dispatch(actions.machineEdit.updateMachineCoordinates(props));
  };

  const updateMachineStatus = (selectedMachine: Machine | null, changedUseEndHour?: string) => {
    if (!selectedMachine) {
      return;
    }
    changeMachineUseEndHourModal.submit(selectedMachine, changedUseEndHour, {
      onSuccess: (response) => {
        const status =
          selectedMachine.status === MachineStatus.STAND_BY ? MachineStatus.RUNNING : MachineStatus.STAND_BY;
        const update_date = response.timestamp.update_date;
        setSelectedMachine({
          ...selectedMachine,
          status,
          use_end_hour: changedUseEndHour ?? selectedMachine.use_end_hour,
          timestamp: {
            ...selectedMachine.timestamp,
            update_date,
          },
        });
        machineMoveableRef.current = null;
      },
    });
  };

  const updateKeepOutAreasCoordinates = (props: UpdateKeepOutAreasCoordinatesProps) => {
    dispatch(actions.keepOutAreas.updateKeepOutAreasCoordinates(props));
  };

  const isDisabledMachineStatusButton = (useHour: string) => {
    // 未来の重機は稼動中にできない
    return moment().isBefore(formatUseHour(useHour));
  };

  const shouldChangeMachineUseEndHour = (useHour: string) => {
    // ToがNOW以前の場合、ステータスを稼動中に変更するのと同時に終了時間を変更する必要がある
    return moment().isSameOrAfter(formatUseHour(useHour));
  };

  // MiniMapの操作
  const [draggingReviewMap, setDraggingReviewMap] = useState(false);

  const getRelativeClickPosition = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const clickX = e.pageX;
    const clickY = e.pageY;

    const clientRect = e.currentTarget.getBoundingClientRect();
    const positionX = clientRect.left + window.pageXOffset;
    const positionY = clientRect.top + window.pageYOffset;

    return { x: clickX - positionX, y: clickY - positionY };
  };

  const updateReviewMap = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const position = getRelativeClickPosition(e);
    const { offsetWidth, offsetHeight } = e.currentTarget;
    let x = position.x - reviewMap.width / 2;
    let y = position.y - reviewMap.height / 2;

    if (x < 0) {
      x = 0;
    } else if (position.x + reviewMap.width / 2 > offsetWidth) {
      x = offsetWidth - reviewMap.width;
    }

    if (y < 0) {
      y = 0;
    } else if (position.y + reviewMap.height / 2 > offsetHeight) {
      y = offsetHeight - reviewMap.height;
    }

    const imageRect = imageRef.current.getBoundingClientRect();
    const widthRatio = imageRect.width / offsetWidth;
    const heightRatio = imageRect.height / offsetHeight;

    const { setTransform, state } = transformRef.current;
    setTransform(x * -widthRatio, y * -heightRatio, state.scale, 0);
    setReviewMap({ ...reviewMap, top: y - 2, left: x - 2 }); // -2はborderの幅分
  };

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setDraggingReviewMap(true);
    updateReviewMap(e);
  };

  const handleDragging = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (draggingReviewMap) {
      updateReviewMap(e);
    }
  };

  const handleDragEnd = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setDraggingReviewMap(false);
  };

  return (
    <div className="bg-[#f3f2ef] min-h-screen">
      <Header />
      {isTooltipOpen && !!selectedArea && (
        <TooltipKeepOutArea
          data={selectedArea}
          leftOrRight={areaTooltipStyle(selectedArea.keepout_area_id)}
          machine={machines.find((m) => m.machine_id === selectedArea.machine_id)}
        />
      )}
      {isTooltipOpen && !!selectedMachine && (
        <TooltipMachine data={selectedMachine} leftOrRight={jukiTooltipStyle(selectedMachine.machine_id)} />
      )}
      <div id="contents" ref={contentRef} className="flex flex-col home px-8 h-[calc(100vh_-_65px)] overflow-hidden">
        <Toolbar
          ref={menuRef}
          canEditMap={navigation.editable}
          canAdd={isPast || !navigation.editable}
          plotPlan={plotplan}
          loggedInUserCompanyId={loggedInUserCompanyId}
          onChangePlotPlan={() => plotPlanSelector.open()}
          onCreateMachine={() => {
            setIsTooltipOpen(false);
            setSelectedMachine(null);
            machineMoveableRef.current = null;
            machineEditorModal.open();
          }}
          onCreateArea={() => {
            setSelectedArea(null);
            setWithMachine(null);
            areaMoveableRef.current = null;
            keepOutAreaEditorModal.open();
          }}
          onChangeEditable={(e) => navigation.setEditable(e.target.checked)}
          onReload={() => {
            dispatch(actions.plotmap.fetchMachines(Number(plotPlanId), layoutDate));
            dispatch(actions.plotmap.fetchKeepOutAreas(Number(plotPlanId), layoutDate));
            dispatch(actions.plotmap.fetchArrows(Number(plotPlanId), layoutDate));
            dispatch(actions.plotmap.fetchTexts(Number(plotPlanId), layoutDate));
          }}
        />
        {plotPlanId && (
          <TransformWrapper
            // NOTE: TransformWrapper を初期化するためにプロットプラン切り替え時に再レンダリングする
            key={`transform-wrapper-${plotPlanId}`}
            ref={transformRef}
            centerZoomedOut
            initialScale={1}
            minScale={minScale}
            maxScale={maxScale}
            pinch={{ disabled: true }}
            panning={{
              disabled:
                selectedMachine != null ||
                selectedArea != null ||
                transformRef.current?.state?.scale === minScale ||
                !!selectedArrow.freeDrawArrow ||
                !!selectedText.freeDrawText,
              velocityDisabled: true,
            }}
            doubleClick={{ disabled: true }}
            velocityAnimation={{ disabled: true }}
            alignmentAnimation={{ disabled: true, sizeX: 0, sizeY: 0 }}
            onPanning={updateNavigationArea}
            onWheel={updateNavigationArea}
          >
            <div ref={operationRef}>
              <NavigationToolbar
                opacity={navigation.opacity}
                displayNavi={navigation.displayNavi}
                onChangeOpacity={(opacity) => navigation.setOpacity(opacity)}
                onChangeMapDisplayNavi={(displayNavi) => navigation.setDisplayNavi(displayNavi)}
                onZoomOut={handleZoomOut}
                onZoomIn={handleZoomIn}
                magnificationRatio={magnificationRatio}
                onDisplayHelpModal={() => helpModal.open()}
                disableFreeDraw={isPast || !navigation.editable}
                // Arrow
                isArrowDrawing={drawingArrow.isDrawing}
                selectedArrow={selectedArrow.freeDrawArrow}
                arrowColor={drawingArrow.isDrawing ? drawingArrow.color : selectedArrow.freeDrawArrow?.color}
                arrowWidth={drawingArrow.isDrawing ? drawingArrow.thickness : selectedArrow.freeDrawArrow?.thickness}
                onClickEditArrow={() => editArrowModal.open()}
                onStartDrawingArrow={() => {
                  drawingArrow.init();
                }}
                onClickCancelArrow={() => {
                  drawingArrow.end();
                  selectedArrow.end();
                }}
                onClickCopyArrow={() => copyArrowModal.open()}
                onClickDeleteArrow={() => deleteArrowModal.open()}
                // Text
                selectedText={selectedText.freeDrawText}
                textColor={selectedText.freeDrawText ? selectedText.freeDrawText.color : loggedInUserCompanyIconColor}
                textSize={selectedText.freeDrawText ? selectedText.freeDrawText.font_size : 12}
                onAddText={() => editTextModal.open()}
                onClickEditText={() => editTextModal.open()}
                onClickCopyText={() => copyTextModal.open()}
                onClickDeleteText={() => deleteTextModal.open()}
              />
            </div>
            <div
              ref={mapRef}
              className="flex items-center justify-center border border-solid grow bg-[#ccc] relative overflow-hidden"
            >
              <TransformComponent
                contentStyle={{ width: "100%" }}
                wrapperStyle={{
                  width: "100%",
                  maxHeight: `${mapHeight}px`,
                  position: "relative",
                }}
              >
                <div className={`${!mapImage ? "hidden" : ""} relative w-full`}>
                  <img className="w-full" ref={imageRef} src={mapImage} onLoad={handleImageLoaded} />
                  <div
                    className={`${
                      navigation.opacity ? "" : "hidden"
                    } pointer-events-none absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.5)]`}
                  />
                  <div ref={setDrawScreenElem} className={`absolute top-0 left-0 w-full h-full`}>
                    <FreeDrawMain
                      mainScreenElem={drawScreenElem}
                      mapRatio={mapRatio}
                      isModalOpen={
                        editArrowModal.isOpen ||
                        copyArrowModal.isOpen ||
                        deleteArrowModal.isOpen ||
                        editTextModal.isOpen ||
                        copyTextModal.isOpen ||
                        deleteTextModal.isOpen
                      }
                      // 矢印
                      arrows={arrows}
                      texts={texts}
                      selectedArrow={selectedArrow.freeDrawArrow}
                      onClickArrow={(arrow) => {
                        selectedText.end();
                        selectedArrow.start(arrow);
                      }}
                      onMoveArrow={(arrow: FreeDrawArrow) => selectedArrow.changeCoords(arrow)}
                      onSelectArrowEnd={selectedArrow.end}
                      // テキスト
                      selectedText={selectedText.freeDrawText}
                      onClickText={(text) => {
                        selectedArrow.end();
                        selectedText.start(text);
                      }}
                      onMoveText={selectedText.changeCoord}
                      onSelectTextEnd={selectedText.end}
                      disabled={!navigation.editable}
                    />
                  </div>
                </div>
                <div className="area-wrapper [&_.moveable-control-box]:z-0">
                  {keepoutAreas.map((area) => (
                    <div
                      id={`area-${area.keepout_area_id}`}
                      className="area-item fade-in"
                      key={`area-${area.keepout_area_id}`}
                      onClick={() => {
                        setSelectedArea(area);
                        setIsTooltipOpen(true);
                        if (navigation.editable) {
                          setWithMachine(machines.find((v) => area.machine_id === v.machine_id));
                          selectedAreaRef.current = document.querySelector(`#area-${area.keepout_area_id}`);
                          areaMoveableRef.current = document.querySelector(`#area-moveable-${area.keepout_area_id}`);
                          setKeepOutAreaSubMenuRef(document.querySelector(`#area-${area.keepout_area_id}`));
                        }
                      }}
                      style={{
                        position: "absolute",
                        top: Math.round(area.y * mapRatio),
                        left: Math.round(area.x * mapRatio),
                        width: Math.round(area.w * mapRatio),
                        height: Math.round(area.h * mapRatio),
                        background: `#${area.icon_color}4d`,
                      }}
                    >
                      <KeepOutAreaObject area={area} />
                    </div>
                  ))}

                  {!isDragging && !isAnyModalOpen && selectedArea && (
                    <KeepOutAreaSubMenu
                      area={selectedArea}
                      referenceElement={keepOutAreaSubMenuRef}
                      scale={scale}
                      control={{
                        isPast,
                        isAdmin,
                        isUser,
                        isAreaFromOtherCompany,
                      }}
                      onEdit={(e) => {
                        e.stopPropagation();
                        areaMoveableRef.current = null;
                        setWithMachine(machines.find((v) => selectedArea.machine_id === v.machine_id));
                        keepOutAreaEditorModal.open();
                        setIsTooltipOpen(false);
                      }}
                      onDuplicate={(e) => {
                        e.stopPropagation();
                        areaMoveableRef.current = null;
                        copyModal.openKeepOutArea(selectedArea);
                        setIsTooltipOpen(false);
                      }}
                      onDelete={(e) => {
                        e.stopPropagation();
                        areaMoveableRef.current = null;
                        deleteAreaModal.open();
                        setIsTooltipOpen(false);
                      }}
                    />
                  )}

                  <Moveable
                    target={areaMoveableRef.current}
                    resizable={!isPast && (!isUser || !isAreaFromOtherCompany)}
                    keepRatio={false}
                    throttleResize={1}
                    edge={false}
                    zoom={1}
                    origin={false}
                    padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                    draggable={true}
                    throttleDrag={0}
                    onDrag={({ beforeTranslate }) => {
                      const target = selectedAreaRef.current;
                      if (isPast || (isUser && isAreaFromOtherCompany)) {
                        return;
                      }

                      const imageRect = imageRef.current.getBoundingClientRect();

                      // X軸の制限
                      let x = beforeTranslate[0];
                      const moveX = parseInt(target.style.left) + beforeTranslate[0];

                      if (moveX < 0) {
                        x = -parseInt(target.style.left);
                      } else if (moveX + parseInt(target.style.width) > imageRect.width * scale) {
                        x = imageRect.width * scale - parseInt(target.style.left) - parseInt(target.style.width);
                      }

                      // Y軸の制限
                      let y = beforeTranslate[1];
                      const moveY = parseInt(target.style.top) + beforeTranslate[1];

                      if (moveY < 0) {
                        y = -parseInt(target.style.top);
                      } else if (moveY + parseInt(target.style.height) > imageRect.height * scale) {
                        y = imageRect.height * scale - parseInt(target.style.top) - parseInt(target.style.height);
                      }

                      setIsDragging(true);
                      target.style.transform = `translate(${x}px, ${y}px)`;
                      if (selectedArea.machine_id) {
                        const machine = document.querySelector<HTMLElement>(`#machine-${selectedArea.machine_id}`);
                        if (machine) {
                          machine.style.transform = `translate(${x}px, ${y}px)`;
                        }
                      }
                    }}
                    onDragEnd={({ isDrag }) => {
                      const target = selectedAreaRef.current;
                      if (isPast || (isUser && isAreaFromOtherCompany)) {
                        return;
                      }

                      // NOTE: チールチップメニューのクリック等でもonDragEndは発火するが、座標を移動してなければ更新不要
                      if (isDrag) {
                        setIsDragging(false);
                        const { translateX, translateY } = getTranslateXY(target);
                        const keepOutAreaX = Math.round((parseInt(target.style.left) + translateX) / mapRatio);
                        const keepOutAreaY = Math.round((parseInt(target.style.top) + translateY) / mapRatio);
                        const w = selectedArea.w;
                        const h = selectedArea.h;

                        const machine = machines.find((m) => m.machine_id === selectedArea.machine_id);

                        // 紐付きかどうかでAPIを変える
                        if (machine) {
                          // 立入禁止エリアに紐づく重機の位置更新
                          const { machine_id } = machine;
                          const m = document.querySelector<HTMLElement>(`#machine-${machine_id}`);
                          const machineX = Math.round((parseInt(m.style.left) + translateX + 20) / mapRatio);
                          const machineY = Math.round((parseInt(m.style.top) + translateY + 20) / mapRatio);

                          updateMachineKeepOutAreasCoordinates({
                            input: {
                              machine: {
                                machine_id: `${machine.machine_id}`,
                                x: machineX,
                                y: machineY,
                                timestamp: machine.timestamp,
                              },
                              keepout_area: {
                                keepout_area_id: selectedArea.keepout_area_id,
                                x: keepOutAreaX,
                                y: keepOutAreaY,
                                timestamp: selectedArea.timestamp,
                              },
                            },
                            onSuccess: (response) => {
                              target.style.transform = null;
                              m.style.transform = null;

                              setSelectedArea((prev) => ({
                                ...prev,
                                x: keepOutAreaX,
                                y: keepOutAreaY,
                                w,
                                h,
                                timestamp: {
                                  ...selectedArea.timestamp,
                                  update_date: response.keepoutArea.timestamp.update_date,
                                },
                              }));
                            },
                          });
                        } else {
                          // 座標の更新
                          updateKeepOutAreasCoordinates({
                            input: {
                              keepout_area_id: selectedArea.keepout_area_id,
                              x: keepOutAreaX,
                              y: keepOutAreaY,
                              w,
                              h,
                              timestamp: {
                                update_date: selectedArea.timestamp.update_date,
                              },
                            },
                            onSuccess: (response) => {
                              target.style.transform = null;
                              setSelectedArea((prev) => ({
                                ...prev,
                                x: keepOutAreaX,
                                y: keepOutAreaY,
                                w,
                                h,
                                timestamp: {
                                  ...selectedArea.timestamp,
                                  update_date: response.timestamp.update_date,
                                },
                              }));
                            },
                          });
                        }
                      }
                    }}
                    onResizeStart={() => {
                      const target = selectedAreaRef.current;
                      resizeTarget.current = {
                        top: parseInt(target.style.top),
                        left: parseInt(target.style.left),
                        width: parseInt(target.style.width),
                        height: parseInt(target.style.height),
                      };
                    }}
                    onResize={({ width, height, direction, drag }) => {
                      const target = selectedAreaRef.current;
                      const beforeTranslate = drag.beforeTranslate;
                      const machine = document.querySelector<HTMLElement>(`#machine-${selectedArea.machine_id}`);

                      let w = width;
                      let h = height;
                      let x = beforeTranslate[0];
                      let y = beforeTranslate[1];
                      const resizeTargetStyle = resizeTarget.current;

                      // 左辺を動かしている時の制限
                      if (direction[0] === -1) {
                        // 左に動かした時の左端を考慮
                        if (resizeTargetStyle.left + x <= 0) {
                          x = -resizeTargetStyle.left;
                          w = resizeTargetStyle.width + resizeTargetStyle.left;
                        }

                        // 右に動かした時に重機の位置を考慮
                        if (machine && resizeTargetStyle.left + x >= parseInt(machine.style.left) + 20) {
                          x = parseInt(machine.style.left) + 20 - resizeTargetStyle.left;
                          w = resizeTargetStyle.width - x;
                        }
                      }

                      // 右辺を動かしている時の制限
                      else if (direction[0] === 1) {
                        // 右に動かした時の右端を考慮
                        if (resizeTargetStyle.left + width >= imageRef.current.offsetWidth) {
                          w = imageRef.current.offsetWidth - resizeTargetStyle.left;
                        }

                        // 左に動かした時に重機の位置を考慮
                        if (machine && resizeTargetStyle.left + width <= parseInt(machine.style.left) + 20) {
                          w = parseInt(machine.style.left) + 20 - resizeTargetStyle.left;
                        }
                      }

                      // 上辺を動かしている時の制限
                      if (direction[1] === -1) {
                        // 上に動かした時の上端を考慮
                        if (resizeTargetStyle.top + y <= 0) {
                          y = -resizeTargetStyle.top;
                          h = resizeTargetStyle.height + resizeTargetStyle.top;
                        }

                        // 下に動かした時に重機の位置を考慮
                        if (machine && resizeTargetStyle.top + y >= parseInt(machine.style.top) + 20) {
                          y = parseInt(machine.style.top) + 20 - resizeTargetStyle.top;
                          h = resizeTargetStyle.height - y;
                        }
                      }

                      // 下辺を動かしている時の制限
                      else if (direction[1] === 1) {
                        // 下に動かした時の下端を考慮
                        if (resizeTargetStyle.top + height >= imageRef.current.offsetHeight) {
                          h = imageRef.current.offsetHeight - resizeTargetStyle.top;
                        }

                        // 上に動かした時に重機の位置を考慮
                        if (machine && resizeTargetStyle.top + height <= parseInt(machine.style.top) + 20) {
                          h = parseInt(machine.style.top) + 20 - resizeTargetStyle.top;
                        }
                      }

                      // エリアの最小サイズが50px未満にならない様にする
                      if (w < 50 * mapRatio) {
                        w = Math.ceil(50 * mapRatio);
                      }

                      if (h < mapRatio * 50) {
                        h = Math.ceil(50 * mapRatio);
                      }

                      setIsDragging(true);
                      target.style.width = `${w}px`;
                      target.style.height = `${h}px`;
                      target.style.transform = `translate(${x}px, ${y}px)`;
                    }}
                    onResizeEnd={({ isDrag }) => {
                      const target = selectedAreaRef.current;
                      // NOTE: チールチップメニューのクリック等でもonDragEndは発火するが、座標を移動してなければ更新不要
                      if (isDrag) {
                        setIsDragging(false);
                        resizeTarget.current = null;
                        const { translateX, translateY } = getTranslateXY(target);
                        const x = Math.round((parseInt(target.style.left) + translateX) / mapRatio);
                        const y = Math.round((parseInt(target.style.top) + translateY) / mapRatio);
                        // pxを除外
                        const w = Math.round(parseInt(target.style.width) / mapRatio);
                        const h = Math.round(parseInt(target.style.height) / mapRatio);

                        // 矩形サイズの更新
                        updateKeepOutAreasCoordinates({
                          input: {
                            keepout_area_id: selectedArea.keepout_area_id,
                            x,
                            y,
                            w,
                            h,
                            timestamp: {
                              update_date: selectedArea.timestamp.update_date,
                            },
                          },
                          onSuccess: (response) => {
                            target.style.transform = null;
                            setSelectedArea((prev) => ({
                              ...prev,
                              x,
                              y,
                              w,
                              h,
                              timestamp: {
                                ...selectedArea.timestamp,
                                update_date: response.timestamp.update_date,
                              },
                            }));
                          },
                        });
                      }
                    }}
                  />
                </div>

                <div className="machine-wrapper">
                  {machines.map((data) => (
                    <div
                      id={`machine-${data.machine_id}`}
                      key={`machine-${data.machine_id}`}
                      className="fade-in"
                      style={{
                        position: "absolute",
                        top: Math.round(data.y * mapRatio) - 20,
                        left: Math.round(data.x * mapRatio) - 20,
                        width: JUKI_ICON_SIZE,
                        height: JUKI_ICON_SIZE,
                      }}
                    >
                      <div
                        className="relative"
                        onClick={() => {
                          setSelectedMachine(data);
                          setIsTooltipOpen(true);
                          if (navigation.editable) {
                            selectedMachineRef.current = document.querySelector(`#machine-${data.machine_id}`);
                            machineMoveableRef.current = document.querySelector(`#machine-moveable-${data.machine_id}`);
                            setMachineSubMenuRef(document.querySelector(`#machine-${data.machine_id}`));
                          }
                        }}
                      >
                        <MachineObject
                          machine={data}
                          selected={selectedMachine && data.machine_id === selectedMachine.machine_id}
                        />
                      </div>
                    </div>
                  ))}

                  {!isDragging && !isAnyModalOpen && selectedMachine && (
                    <MachineSubMenu
                      referenceElement={machineSubMenuRef}
                      machine={selectedMachine}
                      scale={scale}
                      control={{
                        isPast,
                        isToday,
                        isAdmin,
                        isUser,
                        isMachineFromOtherCompany,
                        isDisableMachineStatusButton: isDisabledMachineStatusButton(selectedMachine?.use_start_hour),
                      }}
                      onEdit={(e) => {
                        e.stopPropagation();
                        machineMoveableRef.current = null;
                        machineEditorModal.open();
                        setIsTooltipOpen(false);
                      }}
                      onDuplicate={(e) => {
                        e.stopPropagation();
                        machineMoveableRef.current = null;
                        copyModal.openMachine(selectedMachine);
                        setIsTooltipOpen(false);
                      }}
                      onDelete={(e) => {
                        e.stopPropagation();
                        machineMoveableRef.current = null;
                        deleteMachineModal.open();
                        setIsTooltipOpen(false);
                      }}
                      onCreateKeepOutArea={(e) => {
                        e.stopPropagation();
                        machineMoveableRef.current = null;
                        setWithMachine(selectedMachine);
                        keepOutAreaEditorModal.open();
                        setIsTooltipOpen(false);
                      }}
                      onChangeStatus={(e) => {
                        e.stopPropagation();

                        if (
                          // 使用時間に関わらず作業完了をクリックした際はステータス変更を行う
                          selectedMachine.status !== MachineStatus.RUNNING &&
                          shouldChangeMachineUseEndHour(selectedMachine?.use_end_hour)
                        ) {
                          changeMachineUseEndHourModal.open();

                          return;
                        }

                        updateMachineStatus(selectedMachine);
                      }}
                    />
                  )}

                  <Moveable
                    target={machineMoveableRef.current}
                    origin={false}
                    draggable={true}
                    throttleDrag={0}
                    onDrag={({ beforeTranslate }) => {
                      const target = selectedMachineRef.current;
                      if (isPast || (isUser && isMachineFromOtherCompany)) {
                        return;
                      }

                      const imageRect = imageRef.current.getBoundingClientRect();

                      // 紐づくエリアがある場合、移動制限はエリアを基準に計算
                      const limitTarget = selectedMachine.keepout_area_id
                        ? document.querySelector<HTMLElement>(`#area-${selectedMachine.keepout_area_id}`)
                        : target;

                      // マシーンは中心が基準位置になるため、アイコンの半分サイズ分引く
                      const margin = selectedMachine.keepout_area_id ? 0 : 20;

                      // X軸の制限
                      let x = beforeTranslate[0];
                      const moveX = parseInt(limitTarget.style.left) + beforeTranslate[0] + margin;

                      if (moveX < 0) {
                        x = -parseInt(limitTarget.style.left) - margin;
                      } else if (
                        moveX + parseInt(limitTarget.style.width) - margin >
                        imageRect.width * scale + margin
                      ) {
                        x =
                          imageRect.width * scale -
                          parseInt(limitTarget.style.left) -
                          parseInt(limitTarget.style.width) +
                          margin;
                      }

                      // Y軸の制限
                      let y = beforeTranslate[1];
                      const moveY = parseInt(limitTarget.style.top) + beforeTranslate[1] + margin;

                      if (moveY < 0) {
                        y = -parseInt(limitTarget.style.top) - margin;
                      } else if (
                        moveY + parseInt(limitTarget.style.height) - margin >
                        imageRect.height * scale + margin
                      ) {
                        y =
                          imageRect.height * scale -
                          parseInt(limitTarget.style.top) -
                          parseInt(limitTarget.style.height) +
                          margin;
                      }

                      setIsDragging(true);
                      target.style.transform = `translate(${x}px, ${y}px)`;

                      if (selectedMachine.keepout_area_id) {
                        const area = document.querySelector<HTMLElement>(`#area-${selectedMachine.keepout_area_id}`);
                        if (area) {
                          area.style.transform = `translate(${x}px, ${y}px)`;
                        }
                      }
                    }}
                    onDragEnd={({ isDrag }) => {
                      const target = selectedMachineRef.current;
                      if (isPast || (isUser && isMachineFromOtherCompany)) {
                        return;
                      }

                      // NOTE: チールチップメニューのクリック等でもonDragEndは発火するが、座標を移動してなければ更新不要
                      if (isDrag) {
                        setIsDragging(false);
                        const { translateX, translateY } = getTranslateXY(target);
                        const machineX = Math.round((parseInt(target.style.left) + translateX + 20) / mapRatio);
                        const machineY = Math.round((parseInt(target.style.top) + translateY + 20) / mapRatio);

                        const area = keepoutAreas.find((a) => a.keepout_area_id === selectedMachine.keepout_area_id);

                        // 紐付きかどうかでAPIを変える
                        if (area) {
                          const { keepout_area_id, timestamp } = area;
                          const a = document.querySelector<HTMLElement>(`#area-${keepout_area_id}`);
                          const keepOutAreaX = Math.round((parseInt(a.style.left) + translateX) / mapRatio);
                          const keepOutAreaY = Math.round((parseInt(a.style.top) + translateY) / mapRatio);

                          updateMachineKeepOutAreasCoordinates({
                            input: {
                              machine: {
                                machine_id: selectedMachine.machine_id?.toString(),
                                x: machineX,
                                y: machineY,
                                timestamp: {
                                  update_date: selectedMachine.timestamp.update_date,
                                },
                              },
                              keepout_area: {
                                keepout_area_id: `${keepout_area_id}`,
                                x: keepOutAreaX,
                                y: keepOutAreaY,
                                timestamp,
                              },
                            },
                            onSuccess: (response) => {
                              target.style.transform = null;
                              a.style.transform = null;

                              setSelectedMachine((prev) => ({
                                ...prev,
                                x: machineX,
                                y: machineY,
                                timestamp: {
                                  ...selectedMachine.timestamp,
                                  update_date: response.machine.timestamp.update_date,
                                },
                              }));
                            },
                          });
                        } else {
                          // 座標の更新
                          updateMachineCoordinates({
                            input: {
                              machine_id: selectedMachine.machine_id?.toString(),
                              x: machineX,
                              y: machineY,
                              timestamp: {
                                update_date: selectedMachine.timestamp.update_date,
                              },
                            },
                            onSuccess: (response) => {
                              target.style.transform = null;
                              setSelectedMachine((prev) => ({
                                ...prev,
                                x: machineX,
                                y: machineY,
                                timestamp: {
                                  ...selectedMachine.timestamp,
                                  update_date: response.timestamp.update_date,
                                },
                              }));
                            },
                          });
                        }
                      }
                    }}
                  />
                </div>
                <div className={`${drawingArrow.isDrawing ? "" : "hidden"} absolute top-0 left-0 w-full h-full`}>
                  <ArrowDrawStage
                    isDrawing={drawingArrow.isDrawing}
                    mainScreenElem={drawScreenElem}
                    mapRatio={mapRatio}
                    companyId={loggedInUserCompanyId}
                    plotPlanId={Number(plotPlanId)}
                    drawingColor={drawingArrow.color}
                    drawingThickness={drawingArrow.thickness}
                    drawingCoords={drawingArrow.coords}
                    editing={editArrowModal.isOpen}
                    onAddFreeDrawingCoord={(coord: [number, number]) => drawingArrow.addCoord(coord)}
                    onFreeDrawEnd={drawingArrow.end}
                  />
                </div>
              </TransformComponent>
              {/* ナビ */}
              <div
                onMouseDown={handleDragStart}
                onMouseMove={handleDragging}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
                className={
                  navigation.displayNavi
                    ? "absolute top-[10px] right-[10px] shadow-lg border-2 border-[#888] border-solid z-auto"
                    : "hidden"
                }
                style={{
                  width: REVIEW_MAP_WIDTH,
                  height: reviewMap.height ? "auto" : REVIEW_MAP_HEIGHT,
                }}
              >
                <img className="pointer-events-none" src={mapImage} />
                <div
                  className="absolute border-2 border-[#c00] border-solid pointer-events-none"
                  style={{ ...reviewMap }}
                />
              </div>
            </div>
          </TransformWrapper>
        )}
        {/* 重機一覧 */}
        <div ref={tableRef}>
          <MachineListHeader
            displayMachineList={navigation.showMachineList}
            onToggleMachineList={() => navigation.toggleMachineList()}
            filter={navigation.machineListFilter}
            onFilter={(v) => navigation.setMachineListFilter(v)}
          />
          {navigation.showMachineList && (
            <MachineList
              filter={navigation.machineListFilter}
              onZoomMachine={(machineId) => zoomToElement(machineId)}
            />
          )}
        </div>
        {/* 立入禁止エリア編集モーダル */}
        {keepOutAreaEditorModal.isOpen && (
          <KeepOutAreaEdit
            keepOutAreaData={selectedArea}
            plotPlanId={Number(plotPlanId)}
            onClose={() => {
              keepOutAreaEditorModal.close();
              setSelectedArea(null);
              setWithMachine(null);
              selectedAreaRef.current = null;
            }}
            initialX={initialPosition.keepOutArea.initialX}
            initialY={initialPosition.keepOutArea.initialY}
            withMachine={withMachine}
          />
        )}
        {plotPlanSelector.isOpen && <PlotPlanSelector onClose={() => plotPlanSelector.close()} />}
        {/* 重機編集モーダル */}
        {machineEditorModal.isOpen && (
          <MachineEditor
            machineData={selectedMachine}
            plotPlanId={plotPlanId}
            onClose={() => {
              machineEditorModal.close();
              setSelectedMachine(null);
              selectedMachineRef.current = null;
            }}
            initialX={initialPosition.machine.initialX}
            initialY={initialPosition.machine.initialY}
          />
        )}

        {/* 重機複製モーダル */}
        {copyModal.target === "machine" && (
          <CopyMachineKeepOutAreaModal
            isCopyMachine={true}
            onClose={() => {
              copyModal.close();
              setSelectedMachine(null);
              selectedMachineRef.current = null;
            }}
            onSubmit={({ layoutDateFrom, layoutDateTo, copyOption }) => {
              if (copyOption === ASSOCIATED_COPY) {
                copyModal.copyMachineWithKeepOutArea(layoutDateFrom, layoutDateTo);
              } else {
                copyModal.copyMachine(layoutDateFrom, layoutDateTo);
              }
            }}
            machineId={copyModal.machineId.toString()}
            keepOutAreaId={copyModal.keepOutAreaId?.toString()}
          />
        )}

        {/* 重機削除モーダル */}
        {deleteMachineModal.isOpen && (
          <DeleteMachineModal
            machine={selectedMachine}
            onClose={() => {
              deleteMachineModal.close();
              setSelectedMachine(null);
              selectedMachineRef.current = null;
            }}
          />
        )}

        {/* 終了時間調整モーダル */}
        {changeMachineUseEndHourModal.isOpen && (
          <ChangeMachineUseEndHourModal
            onClose={() => {
              changeMachineUseEndHourModal.close();
              setSelectedMachine(null);
              selectedMachineRef.current = null;
            }}
            onSubmit={({ useEndHour }) => {
              updateMachineStatus(selectedMachine, useEndHour);
            }}
            useStartHourProp={selectedMachine?.use_start_hour}
            useEndHourProp={selectedMachine?.use_end_hour}
          />
        )}

        {/* 立ち入り禁止エリア複製モーダル */}
        {copyModal.target === "area" && (
          <CopyMachineKeepOutAreaModal
            isCopyKeepOutArea={true}
            onClose={() => {
              copyModal.close();
              setSelectedArea(null);
              setWithMachine(null);
              selectedAreaRef.current = null;
            }}
            onSubmit={({ layoutDateFrom, layoutDateTo, copyOption }) => {
              if (copyOption === ASSOCIATED_COPY) {
                copyModal.copyMachineWithKeepOutArea(layoutDateFrom, layoutDateTo);
              } else {
                copyModal.copyKeepOutArea(layoutDateFrom, layoutDateTo);
              }
            }}
            machineId={copyModal.machineId?.toString()}
            keepOutAreaId={copyModal.keepOutAreaId.toString()}
          />
        )}

        {/* 立ち入り禁止エリア削除モーダル */}
        {deleteAreaModal.isOpen && (
          <DeleteKeepOutAreaModal
            area={selectedArea}
            onClose={() => {
              deleteAreaModal.close();
              setSelectedArea(null);
              setWithMachine(null);
              selectedAreaRef.current = null;
            }}
            onSuccess={() => {
              setSelectedMachine((prev) => ({
                ...prev,
                keepout_area_id: null,
              }));
            }}
          />
        )}

        {/* 矢印編集モーダル */}
        {editArrowModal.isOpen && (
          <EditFreeDrawArrowModal
            companyId={loggedInUserCompanyId}
            freeDrawArrow={selectedArrow.freeDrawArrow}
            color={drawingArrow.isDrawing ? drawingArrow.color : selectedArrow.freeDrawArrow?.color}
            thickness={drawingArrow.isDrawing ? drawingArrow.thickness : selectedArrow.freeDrawArrow?.thickness}
            onSaveDrawingArrow={(color, thickness) => {
              drawingArrow.changeColorAndThickness(color, thickness);
              editArrowModal.close();
            }}
            onSave={(arrow: FreeDrawArrow) => {
              selectedArrow.changeColorAndThickness(arrow);
              editArrowModal.close();
            }}
            onClose={() => editArrowModal.close()}
          />
        )}

        {/* 矢印複製モーダル*/}
        {copyArrowModal.isOpen && (
          <CopyFreeDrawModal
            onClose={copyArrowModal.close}
            onSubmit={(dateRange: { layoutDateFrom: string; layoutDateTo: string }) => {
              dispatch(
                actions.arrows.copyArrow({
                  input: {
                    arrow_id: selectedArrow.freeDrawArrow.arrow_id,
                    layout_date_from: dateRange.layoutDateFrom,
                    layout_date_to: dateRange.layoutDateTo,
                  },
                  onSuccess: () => selectedArrow.end(),
                })
              );
            }}
          />
        )}

        {/* 矢印削除モーダル */}
        {deleteArrowModal.isOpen && (
          <DeleteConfirmModal
            id="arrow-delete-modal"
            message={t("arrow") + t("msg_deleted")}
            onClose={deleteArrowModal.close}
            onSubmit={() => {
              dispatch(
                actions.arrows.deleteArrow({
                  input: {
                    arrow_id: selectedArrow.freeDrawArrow.arrow_id,
                    timestamp: {
                      update_date: selectedArrow.freeDrawArrow.timestamp?.update_date,
                    },
                  },
                  onSuccess: () => {
                    selectedArrow.end();
                    deleteArrowModal.close();
                  },
                })
              );
            }}
          />
        )}

        {/* テキスト編集モーダル */}
        {editTextModal.isOpen && (
          <EditFreeDrawTextModal
            companyId={loggedInUserCompanyId}
            freeDrawText={selectedText.freeDrawText}
            defaultColor={loggedInUserCompanyIconColor}
            defaultSize={12}
            onClose={editTextModal.close}
            onChange={selectedText.change}
            initialX={initialPosition.machine.initialX}
            initialY={initialPosition.machine.initialY}
          />
        )}

        {/* テキスト複製モーダル*/}
        {copyTextModal.isOpen && (
          <CopyFreeDrawModal
            onClose={copyTextModal.close}
            onSubmit={(dateRange: { layoutDateFrom: string; layoutDateTo: string }) => {
              dispatch(
                actions.texts.copyText({
                  input: {
                    freetext_id: selectedText.freeDrawText.freetext_id,
                    layout_date_from: dateRange.layoutDateFrom,
                    layout_date_to: dateRange.layoutDateTo,
                  },
                  onSuccess: () => selectedText.end(),
                })
              );
            }}
          />
        )}

        {/* テキスト削除モーダル */}
        {deleteTextModal.isOpen && (
          <DeleteConfirmModal
            id="text-delete-modal"
            message={t("text") + t("msg_deleted")}
            onClose={deleteTextModal.close}
            onSubmit={() => {
              dispatch(
                actions.texts.deleteText({
                  input: {
                    freetext_id: selectedText.freeDrawText.freetext_id,
                    timestamp: {
                      update_date: selectedText.freeDrawText.timestamp?.update_date,
                    },
                  },
                  onSuccess: () => {
                    selectedText.end();
                    deleteTextModal.close();
                  },
                })
              );
            }}
          />
        )}

        {/* ヘルプモーダル */}
        {helpModal.isOpen && <HelpModal companies={companies} onClose={() => helpModal.close()} />}

        {isLoading && <Loading />}
      </div>
    </div>
  );
};
