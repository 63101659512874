import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Machine, KeepOutArea } from "@/sx-layout/components/plotmap/models";

type Props = {
  data: KeepOutArea;
  machine?: Machine;
  leftOrRight: "left-aligned" | "right-aligned";
};

export const TooltipKeepOutArea: FC<Props> = ({ data, machine, leftOrRight }) => {
  const { t } = useTranslation();

  // 紐づきエリアのツールチップの一部は重機のデータを参照する
  // 単独のエリアは自身のデータを参照する
  const useStartHour = machine?.use_start_hour ?? data.use_start_hour;
  const useEndHour = machine?.use_end_hour ?? data.use_end_hour;
  const workContent = machine?.work_content ?? data.work_content;
  const companyName = machine?.company_name ?? data.company_name;
  const chargeUserName = machine?.charge_user_name ?? data.charge_user_name;
  const phoneNumber = machine?.phone_number ?? data.phone_number;
  // 備考は自身のデータを表示する
  const note = data.note;

  return (
    <div
      style={
        leftOrRight === "left-aligned"
          ? {
              left: "15px",
            }
          : {
              right: "15px",
            }
      }
      className={`
      sx-layout-tooltip
      border-4
      border-[#dea321]
      border-solid
      text-[13px]
      shadow-[0_4px_4px_0px_rgba(0,0,0,0.3)]
      rounded-lg
      p-3
      bg-[#fff]
      z-10
      `}
    >
      <img
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          width: "20px",
          height: "20px",
        }}
        src="./img/tooltip_keepout_area.svg"
      />
      {machine?.machine_id && (
        <img
          style={{
            position: "absolute",
            top: "30px",
            right: "10px",
            width: "20px",
            height: "20px",
          }}
          src="./img/icon-link.svg"
        />
      )}
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("operation_time")}</span>
        <span className="w-[180px] text-left">{`${useStartHour}~${useEndHour}`}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("content")}</span>
        <span className="w-[180px] text-left">{workContent}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("belonging")}</span>
        <span className="w-[180px] text-left">{companyName}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("machine_user")}</span>
        <span className="w-[180px] text-left">{chargeUserName}</span>
      </div>
      <div className="flex mb-10">
        <span className="w-[60px] text-right font-bold mr-2">{t("contact")}</span>
        <span className="w-[180px] text-left">{phoneNumber}</span>
      </div>
      <div className="flex">
        <span className="w-[60px] text-right font-bold mr-2">{t("note")}</span>
        <span className="w-[180px] text-left">{note}</span>
      </div>
    </div>
  );
};
