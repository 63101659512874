import { MachineIcon } from "@/sx-layout/common/MachineIcon";
import Title from "@/sx-layout/components/notification/components/NotificationRow/Header/Title";

type Props = {
  notification_type_name: string;
  machine_icon_id: number;
  isRead: boolean;
};

const Header: React.FC<Props> = ({ notification_type_name, machine_icon_id, isRead, isNew }) => {
  return (
    <div className="layout-notification-header">
      <MachineIcon
        machineIconId={machine_icon_id}
        active={true}
        selected={false}
        outline={false}
        shapeColor="#000"
        fillColor={isRead ? "#eff0f3" : isNew ? "#fffad8" : "#fff"}
        className="w-[50px] h-[50px] is-read"
      />
      <Title data={notification_type_name} />
    </div>
  );
};

export default Header;
