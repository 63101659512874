import { Comment } from "./comments";
import { File } from "./files";
import { TimeStamp } from "./timestamp";

export type Task = {
  background_color: number;
  checkpoints: any[];
  comments: Comment[];
  completion_user_id: number;
  completion_user_name: string;
  cost: any;
  display_status: string;
  display_user_name: string;
  document_no: any;
  files: File[];
  group_id: number;
  item_id: number;
  process_company_id: number;
  process_id: number;
  progress: number;
  result_end_date: string | null;
  result_start_date: string | null;
  roles: number[];
  schedule_date: string | null;
  schedule_end_date: string | null;
  schedule_start_date: string | null;
  schedule_type: TaskScheduleTypeType;
  ahead_days?: 1 | 2 | 3 | null;
  status: number;
  task_id: number;
  timestamp: TimeStamp;
  user_id: number | null;
  user_name: string | null;
  valid_flg: boolean;
  weight: number;
  weight_default: number;
  field_t1: number;
  field_t2: number;
  field_t3: number;
  field_t4: string;
  field_t5: number;
  field_t6: number;
  field_t7: string;
  field_t8: string;
  field_t9: number;
  schedule_roles: [number];
  unwork_days: Date[];
};

// 実績ステータス:(0:未完了、1:完了)
export const TaskStatus = {
  INCOMPLETE: 0,
  COMPLETE: 1,
} as const;

export type TaskStatusType = (typeof TaskStatus)[keyof typeof TaskStatus];

// 作業予定種別:(0:空欄、1:○予定、2:◎継続、3:■完了見込み、4:△中断) // backendと同じ命名
export const TaskScheduleType = {
  EMPTY: 0,
  SCHEDULED: 1,
  CONTINUE: 2,
  COMPLETING: 3,
  INTERRUPT: 4,
} as const;

export type TaskScheduleTypeType = (typeof TaskScheduleType)[keyof typeof TaskScheduleType];

export const TaskSwitcherStatus = {
  OFF: -1, // ステータス切替OFF
  NO_PROCESS: 0, // ー:作業なし
  YES_PROCESS: 1, // 作業あり
  SCHEDULE: 2, // ○:予定
  SCHEDULE_2: 9, // ②:2日後予定
  SCHEDULE_3: 10, // ③:3日後予定
  CONTINUE: 3, // ◎:継続
  CONTINUE_2: 11, // ⓶:2日後継続
  CONTINUE_3: 12, // ⓷:3日後継続
  COMPLETING: 4, // ■: 完了見込み
  INTERRUPT: 5, // △:中断
  DONE: 8, // ●:完了
} as const;

export type TaskSwitcherStatusType = (typeof TaskSwitcherStatus)[keyof typeof TaskSwitcherStatus];

export class TaskUtil {
  static scheduleTypeBySwitcherStatus(switcherStatus: TaskSwitcherStatusType): TaskScheduleTypeType | undefined {
    switch (switcherStatus) {
      case TaskSwitcherStatus.NO_PROCESS:
        return TaskScheduleType.EMPTY;
      case TaskSwitcherStatus.YES_PROCESS:
        return TaskScheduleType.EMPTY;
      case TaskSwitcherStatus.SCHEDULE:
      case TaskSwitcherStatus.SCHEDULE_2:
      case TaskSwitcherStatus.SCHEDULE_3:
        return TaskScheduleType.SCHEDULED;
      case TaskSwitcherStatus.CONTINUE:
      case TaskSwitcherStatus.CONTINUE_2:
      case TaskSwitcherStatus.CONTINUE_3:
        return TaskScheduleType.CONTINUE;
      case TaskSwitcherStatus.COMPLETING:
        return TaskScheduleType.COMPLETING;
      case TaskSwitcherStatus.INTERRUPT:
        return TaskScheduleType.INTERRUPT;
      default:
        return undefined;
    }
  }

  static equalsTaskAndSwitcherStatus(
    task: Pick<Task, "valid_flg" | "status" | "schedule_type" | "ahead_days">,
    switcherStatus: TaskSwitcherStatusType
  ): boolean {
    if (switcherStatus === TaskSwitcherStatus.OFF) return false;
    if (!task.valid_flg) {
      // 作業なし
      return switcherStatus === TaskSwitcherStatus.NO_PROCESS;
    }
    if (task.status === TaskStatus.COMPLETE) {
      // 完了
      return switcherStatus === TaskSwitcherStatus.DONE;
    }
    switch (switcherStatus) {
      case TaskSwitcherStatus.YES_PROCESS:
        return task.schedule_type === TaskScheduleType.EMPTY;
      case TaskSwitcherStatus.SCHEDULE:
        return task.schedule_type === TaskScheduleType.SCHEDULED && task.ahead_days === 1;
      case TaskSwitcherStatus.SCHEDULE_2:
        return task.schedule_type === TaskScheduleType.SCHEDULED && task.ahead_days === 2;
      case TaskSwitcherStatus.SCHEDULE_3:
        return task.schedule_type === TaskScheduleType.SCHEDULED && task.ahead_days === 3;
      case TaskSwitcherStatus.CONTINUE:
        return task.schedule_type === TaskScheduleType.CONTINUE && task.ahead_days === 1;
      case TaskSwitcherStatus.CONTINUE_2:
        return task.schedule_type === TaskScheduleType.CONTINUE && task.ahead_days === 2;
      case TaskSwitcherStatus.CONTINUE_3:
        return task.schedule_type === TaskScheduleType.CONTINUE && task.ahead_days === 3;
      case TaskSwitcherStatus.COMPLETING:
        return task.schedule_type === TaskScheduleType.COMPLETING;
      case TaskSwitcherStatus.INTERRUPT:
        return task.schedule_type === TaskScheduleType.INTERRUPT;
      default:
        return false;
    }
  }
}
