import * as constants from "@/constants";
import environments from "@/environments";
import {
  FetchMachineTypesProps,
  FetchCompaniesMachineTypesProps,
  UpdateCompaniesMachineProps,
} from "@/sx-layout/components/settings/machineSetting/actions/types";

export const fetchMachineTypes = (props?: FetchMachineTypesProps) => {
  const endpoint = `${environments.API_BASE_URI}/machine_types`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_FETCH_MACHINE_TYPES,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_FETCH_MACHINE_TYPES,
          payload: response.machine_types,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchCompaniesMachineTypes = (props?: FetchCompaniesMachineTypesProps) => {
  const endpoint = `${environments.API_BASE_URI}/companies_machine_types`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_FETCH_COMPANIES_MACHINE_TYPES,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_FETCH_COMPANIES_MACHINE_TYPES,
          payload: response.machine_type_ids,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateCompaniesMachineTypes = (props: UpdateCompaniesMachineProps) => {
  const { ...restInput } = props.input;

  return {
    type: constants.APP_CALL_API,
    endpoint: `${environments.API_BASE_URI}/companies_machine_types/update`,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(restInput),
    callbacks: {
      begin: () => {
        return {
          type: constants.MACHINE_BEGIN_UPDATE_COMPANIES_MACHINE_TYPES,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.MACHINE_END_UPDATE_COMPANIES_MACHINE_TYPES,
          payload: response,
        };
      },
      error: (response) => {
        console.log("error!!!");

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
