import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import actions from "@/actions/index";
import { cn } from "@/lib/classNames";
import { RootState } from "@/reducers/types";
import { ConstructionSelector } from "@/sx-layout/components/ConstructionSelector";
import { LayoutNotificationBell } from "@/sx-layout/components/notification/components/LayoutNotificationBell";
import NotificationDrawer from "@/sx-layout/components/notification/components/NotificationDrawer";
import { LayoutMenu, Menu } from "@/sx-layout/models";

export const Header = () => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const layoutMenu = useSelector<RootState, LayoutMenu>((state) => state.layoutApp.layoutMenu);
  const construction = useSelector((state) => {
    const c = {
      customerName: "",
      placeName: "",
      constructionName: "",
      constructionId: "",
    };

    const { customerId, placeId, constructionId, customers, places, constructions } = state.construction;

    customers.forEach((customer) => {
      if (customer.customer_id === customerId) {
        c.customerName = customer.customer_name;
      }
    });

    places.forEach((place) => {
      if (place.place_id === placeId) {
        c.placeName = place.place_name;
      }
    });

    constructions.forEach((construction) => {
      if (construction.construction_id === constructionId) {
        c.constructionName = construction.construction_name;
      }
    });

    c.constructionId = constructionId;

    return c;
  });
  const constructionId = construction.constructionId;
  const [isConstructionSelectorOpen, setIsConstructionSelectorOpen] = useState(false);

  const [hoverMenu, setHoverMenu] = useState("");
  const isSwitchable = session.funcLayout === 1 && session.funcProgress === 1;

  const [isNotificationListOpen, setIsNotificationListOpen] = useState(false);

  const handleChangeMenu = (menu: LayoutMenu) => {
    dispatch(actions.layoutApp.changeLayoutMenu(menu));

    history.pushState(undefined, undefined, `/#/${constructionId}/${menu}`);
  };

  const { t } = useTranslation();

  const machineTypeMenu: Menu = useSelector((state) => {
    return state.app.menus.find((menu) => menu.menu_id === 2002);
  });
  const plotPlanMenu: Menu = useSelector((state) => {
    return state.app.menus.find((menu) => menu.menu_id === 2003);
  });

  return (
    <header className="flex flex-col bg-black relative">
      <div className="flex items-center h-[20px] bg-[#d8d7d5]">
        <div
          className="px-8 text-[13px] cursor-pointer hover:underline hover:decoration-auto"
          onClick={() => setIsConstructionSelectorOpen(true)}
        >
          {`${construction.customerName} | ${construction.placeName} | ${construction.constructionName}`}
        </div>
      </div>
      <div className="flex justify-between text-[#fff] text-[13px]">
        <div className="flex">
          <div
            className={`flex items-center justify-center cursor-pointer h-[45px] ${
              isSwitchable && "hover:bg-[#dea221]"
            }`}
          >
            <div
              className="dropdown relative text-center"
              onMouseEnter={() => setHoverMenu("switching")}
              onMouseLeave={() => setHoverMenu("")}
            >
              <button className="font-bold px-8 h-[45px] inline-flex w-full items-center justify-center">
                <img src="./img/sx-layout.svg" className="w-[30px] h-[30px]" />
              </button>
              {isSwitchable && (
                <div
                  className={`z-10 dropdown-menu absolute text-gray-700 w-[180px] bg-[#47505e] ${
                    hoverMenu !== "switching" && "hidden"
                  }`}
                >
                  <div className="">
                    <a
                      onClick={() => dispatch(actions.app.switchSystem("progress"))}
                      className="flex items-center justify-between text-[12px] text-[#fff] hover:bg-[#dea221] hover:text-[#fff] h-[45px] px-8"
                    >
                      <img src="./img/sx-progress.svg" className="w-[30px] h-[30px]" alt="progress" />
                      {t("sx_progress")}
                    </a>
                  </div>
                  <div className="opacity-50 pointer-events-none">
                    <a
                      onClick={() => dispatch(actions.app.switchSystem("layout"))}
                      className="flex items-center justify-between text-[12px] text-[#fff] h-[45px] px-8 cursor-default"
                    >
                      <img src="./img/sx-layout.svg" className="w-[30px] h-[30px]" alt="layout" />
                      {t("sx_layout")}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={cn(
              "flex items-center justify-center min-w-[130px] font-bold cursor-pointer h-[45px] hover:bg-[#dea221]",
              layoutMenu === "machine" && "bg-[#dea221]"
            )}
            onClick={() => handleChangeMenu("machine")}
          >
            <a className="text-[#fff] hover:text-[#fff]">{t("deploy_heavy_machine")}</a>
          </div>
          <div
            className={`
              ${!machineTypeMenu?.active && !plotPlanMenu?.active ? "hidden" : ""}
              flex items-center justify-center min-w-[130px] font-bold cursor-pointer h-[45px] hover:bg-[#dea221]
              ${layoutMenu === "plot_plan" || layoutMenu === "machine_type" ? "bg-[#dea221]" : ""}
              `}
          >
            <div
              className="dropdown relative w-full text-center"
              onMouseEnter={() => setHoverMenu("setting")}
              onMouseLeave={() => setHoverMenu("")}
            >
              <button className={`font-bold px-[16px] py-[8px] inline-flex w-full items-center justify-center`}>
                <span className="mr-[4px]">{t("settings")}</span>
                <svg className="fill-current h-[16px] w-[16px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </button>
              <div
                className={`z-10 dropdown-menu absolute text-gray-700 w-full pt-[4px] ${
                  hoverMenu !== "setting" ? "hidden" : ""
                }`}
              >
                <div className={machineTypeMenu?.active ? "" : "hidden"}>
                  <a
                    className="text-[#fff] bg-[#47505e] hover:bg-[#dea221] hover:text-[#fff] text-left py-[12px] px-[16px] block whitespace-no-wrap"
                    onClick={() => handleChangeMenu("machine_type")}
                  >
                    {t("heavy_machine_settings")}
                  </a>
                </div>
                <div className={plotPlanMenu?.active ? "" : "hidden"}>
                  <a
                    className="text-[#fff] bg-[#47505e] hover:bg-[#dea221] hover:text-[#fff] text-left py-[12px] px-[16px] block whitespace-no-wrap"
                    onClick={() => handleChangeMenu("plot_plan")}
                  >
                    {t("plot_plan")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div
            className="flex items-center justify-center cursor-pointer w-[50px]"
            onClick={() => {
              setIsNotificationListOpen((prev) => !prev);
            }}
            id="layout-notification-bell"
          >
            <LayoutNotificationBell />
          </div>
          <div
            className="flex items-center justify-center cursor-pointer w-[50px] relative text-[12px]"
            onMouseEnter={() => setHoverMenu("userMenu")}
            onMouseLeave={() => setHoverMenu("")}
          >
            <img className="w-[25px] h-[25px]" src="./img/icon-menu-white.svg" alt="menu" />
            <div
              className={`z-10 absolute text-gray-700 w-[170px] right-0 top-[100%] ${
                hoverMenu !== "userMenu" ? "hidden" : ""
              }`}
            >
              <div className="bg-[#47505e]">
                <a className="text-[#fff] hover:text-[#fff] text-left py-[10px] px-[16px] block whitespace-no-wrap border-b border-[#7e8590] mx-[4px]">
                  {session.userName}
                </a>
              </div>
              <div
                className="bg-[#47505e] hover:bg-[#dea221]"
                onClick={() => dispatch(actions.app.showPasswordEditor())}
              >
                <a className="text-[#fff] hover:text-[#fff] text-left py-[10px] px-[16px] block whitespace-no-wrap">
                  {t("change_password")}
                </a>
              </div>
              <div className="bg-[#47505e] hover:bg-[#dea221]" onClick={() => dispatch(actions.app.showLangSelector())}>
                <a className="text-[#fff] hover:text-[#fff] text-left py-[10px] px-[16px] block whitespace-no-wrap">
                  {t("language")}
                </a>
              </div>
              <div className="bg-[#47505e] hover:bg-[#dea221]" onClick={() => dispatch(actions.login.logout())}>
                <a className="text-[#fff] hover:text-[#fff] text-left py-[10px] px-[16px] block whitespace-no-wrap">
                  {t("log_out")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationDrawer
        visible={isNotificationListOpen}
        onClose={() => {
          setIsNotificationListOpen(false);
        }}
      />

      {isConstructionSelectorOpen && <ConstructionSelector onClose={() => setIsConstructionSelectorOpen(false)} />}
    </header>
  );
};
