import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { DateSelector } from "./DateSelector";

import { Button } from "@/sx-layout/common/Button";
import { FreeDrawArrow, FreeDrawText } from "@/sx-layout/components/plotmap/components/freeDraw/models";
import { ArrowTool } from "@/sx-layout/components/plotmap/components/toolbar/components/ArrowTool";
import { TextTool } from "@/sx-layout/components/plotmap/components/toolbar/components/TextTool";

type Props = {
  opacity: number;
  displayNavi: number;
  onChangeOpacity: (_: number) => void;
  onChangeMapDisplayNavi: (_: number) => void;
  onZoomOut: () => void;
  onZoomIn: () => void;
  magnificationRatio: number;
  onDisplayHelpModal: () => void;
  // Free draw (arrow)
  isArrowDrawing: boolean;
  selectedArrow?: FreeDrawArrow;
  disableFreeDraw: boolean;
  arrowColor?: string;
  arrowWidth?: number;
  onStartDrawingArrow: () => void;
  onClickEditArrow: () => void;
  onClickCancelArrow: () => void;
  onClickCopyArrow: () => void;
  onClickDeleteArrow: () => void;
  // Free draw (text)
  selectedText?: FreeDrawText;
  textColor: string;
  textSize: number;
  onAddText: () => void;
  onClickEditText: () => void;
  onClickCopyText: () => void;
  onClickDeleteText: () => void;
};

export const NavigationToolbar: FC<Props> = ({
  opacity,
  displayNavi,
  onChangeOpacity,
  onChangeMapDisplayNavi,
  onZoomOut,
  onZoomIn,
  magnificationRatio,
  onDisplayHelpModal,
  disableFreeDraw,
  isArrowDrawing,
  selectedArrow,
  arrowColor,
  arrowWidth,
  onClickEditArrow,
  onStartDrawingArrow,
  onClickCancelArrow,
  onClickCopyArrow,
  onClickDeleteArrow,
  selectedText,
  textColor,
  textSize,
  onAddText,
  onClickEditText,
  onClickCopyText,
  onClickDeleteText,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center justify-start space-x-6 h-[32px]">
        <DateSelector />
        {!isArrowDrawing && !selectedArrow && !selectedText && (
          <>
            <Button
              id="arrow-add-button"
              disabled={disableFreeDraw}
              className="min-w-[30px] flex items-center justify-center"
              onClick={onStartDrawingArrow}
            >
              <img src="./img/icon-arrow-white.svg" className="mr-2 w-[18px]" />
              <p className="">{t("add_arrow")}</p>
            </Button>
            <Button
              disabled={disableFreeDraw}
              className="min-w-[30px] flex items-center justify-center"
              onClick={onAddText}
            >
              <img src="./img/icon-text-white.svg" className="mr-2 w-[18px]" />
              <p className="">{t("add_text")}</p>
            </Button>
          </>
        )}
        {(isArrowDrawing || !!selectedArrow) && (
          <ArrowTool
            isDrawing={isArrowDrawing}
            selectedArrow={selectedArrow}
            arrowColor={arrowColor}
            arrowWidth={arrowWidth}
            onClickEdit={onClickEditArrow}
            onClickCancel={onClickCancelArrow}
            onClickCopy={onClickCopyArrow}
            onClickDelete={onClickDeleteArrow}
          />
        )}
        {!!selectedText && (
          <TextTool
            selectedText={selectedText}
            color={textColor}
            fontSize={textSize}
            onClickEdit={onClickEditText}
            onClickCopy={onClickCopyText}
            onClickDelete={onClickDeleteText}
          />
        )}
      </div>
      <div className="flex items-center space-x-10">
        <div className="text-[13px] space-x-4">
          <span>{t("floor_plan")}: </span>
          <button
            type="button"
            onClick={() => onChangeOpacity(1)}
            className={`${
              opacity
                ? "font-bold cursor-auto text-[#1c77ff]"
                : "font-normal hover:underline cursor-pointer text-[#888888]"
            }`}
          >
            {t("light")}
          </button>
          <button
            type="button"
            onClick={() => onChangeOpacity(0)}
            className={`${
              opacity
                ? "font-normal hover:underline cursor-pointer text-[#888888]"
                : "font-bold cursor-auto text-[#1c77ff]"
            }`}
          >
            {t("dark")}
          </button>
        </div>
        <div className="text-[13px] space-x-4">
          <span>{t("navigation")}: </span>
          <button
            type="button"
            onClick={() => onChangeMapDisplayNavi(1)}
            className={`${
              displayNavi
                ? "font-bold cursor-auto text-[#1c77ff]"
                : "font-normal hover:underline cursor-pointer text-[#888888]"
            }`}
          >
            {t("display")}
          </button>
          <button
            type="button"
            onClick={() => onChangeMapDisplayNavi(0)}
            className={`${
              displayNavi
                ? "font-normal hover:underline cursor-pointer text-[#888888]"
                : "font-bold cursor-auto text-[#1c77ff]"
            }`}
          >
            {t("non_display")}
          </button>
        </div>
        <div className="flex space-x-4 ">
          <button type="button" onClick={onZoomOut} className="cursor-pointer">
            <img src="./img/icon-zoom-out.svg" className="w-[18px] h-[18px]" />
          </button>
          <span className="w-[40px] text-[13px] text-center">{`${Math.round(magnificationRatio)}%`}</span>
          <button type="button" onClick={onZoomIn} className="cursor-pointer">
            <img src="./img/icon-zoom-in.svg" className="w-[18px] h-[18px]" />
          </button>
        </div>
        <div className="flex space-x-4 ">
          <button type="button" onClick={onDisplayHelpModal} className="cursor-pointer">
            <img src="./img/icon-help.svg" className="w-[18px] h-[18px]" />
          </button>
        </div>
      </div>
    </div>
  );
};
