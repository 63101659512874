import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_US from "../languages/en_US.json";
import ja_JP from "../languages/ja_JP.json";

import storageManager from "./lib/storageManager";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      general: en_US,
    },
    ja: {
      general: ja_JP,
    },
  },
  fallbackLng: "ja",
  ns: ["general"],
  defaultNS: "general",
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
  nsSeparator: "|",
});

export const changeLanguage = (locale) => {
  storageManager.setLocale(locale);
  i18n.changeLanguage(locale);
};

export default i18n;
