import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import RightGridCell from "./RightGridCell";

import { TaskSwitcherStatusType } from "@/models/tasks";
import { RootState } from "@/reducers/types";

type Props = {
  row: any;
  linkageTasks: any;
  showTaskEditor: (taskId, isStatus, linkageInfo) => void;
  switcherStatus: TaskSwitcherStatusType;
  changeTaskStatus: (item_id, task, status) => void;
  isChiba: boolean;
};

const RightGridRow: React.FC<Props> = (props) => {
  const { linkageTasks } = props;

  const [tasks, setTasks] = useState(props.row.tasks);
  const { columns } = useSelector((state: RootState) => state.matrix);
  useEffect(() => {
    const data = [];
    for (const col of columns) {
      const d = props.row.tasks.find((v) => v.process_id === col.process_id);
      d && data.push(d);
    }
    setTasks(data);
  }, [columns, props.row.tasks]);

  return (
    <tr>
      {tasks.map((task, index) => {
        let type = "from";
        let content = [];

        if (task.valid_flg && linkageTasks) {
          content = linkageTasks
            .filter((val) => {
              return val.from_task_id === task.task_id;
            })
            .map((n) => {
              return n.to_category_name + "：" + n.to_item_name + "：" + n.to_process_name;
            });

          if (content.length === 0) {
            content = linkageTasks
              .filter((val) => {
                return val.to_task_id === task.task_id;
              })
              .map((n) => {
                return n.from_category_name + "：" + n.from_item_name + "：" + n.from_process_name;
              });
            if (content.length > 0) type = "to";
          }
        }

        return (
          <RightGridCell
            task={task}
            itemId={props.row.item_id}
            index={index}
            key={index}
            switcherStatus={props.switcherStatus}
            showTaskEditor={props.showTaskEditor}
            changeTaskStatus={props.changeTaskStatus}
            linkageInfo={{ type, num: content.length, content }}
            isChiba={props.isChiba}
          />
        );
      })}
    </tr>
  );
};

export default RightGridRow;
