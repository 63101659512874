import fileDownload from "js-file-download";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useConstructionExtensions } from "@/hooks";
import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { downloadPlotMapFile } from "@/sx-layout/components/plotmap/components/fileExport/actions";
import { DownloadFileParams } from "@/sx-layout/components/plotmap/components/fileExport/models";

type Props = {
  loggedInUserCompanyId?: number;
};

const FileExport: React.FC<Props> = ({ loggedInUserCompanyId }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [queryParams, setQueryParams] = useReducer(
    (prev: DownloadFileParams, next: Partial<DownloadFileParams>) => {
      return {
        ...prev,
        ...next,
      };
    },
    {
      filetype: 1,
      format: "excel",
      plot_plan_id: 0,
      layout_date: new Date(),
      icon_size: 1,
    }
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plotPlanId = useSelector<RootState, string>((state) => state.plotmap.plotPlanId);
  const layoutDate = useSelector<RootState, any>((state) => state.plotmap.layoutDate);

  const { extension } = useConstructionExtensions(13);

  const canExportRequest = useMemo(() => {
    if (!extension) {
      return false;
    }
    const companyIds: number[] = extension.config;

    return companyIds.includes(loggedInUserCompanyId);
  }, [extension, loggedInUserCompanyId]);

  useEffect(() => {
    setQueryParams({ plot_plan_id: parseInt(plotPlanId) ?? 0 });
  }, [plotPlanId]);

  useEffect(() => {
    setQueryParams({ layout_date: layoutDate });
  }, [layoutDate]);

  const onOpenDropdown = () => {
    setIsDropdownOpen(true);
  };

  const onCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const onDownload = (params: DownloadFileParams) => {
    setQueryParams(params);
    dispatch(
      downloadPlotMapFile({
        ...params,
        onBegin: () => {
          setIsDownloading(true);
        },
        onSuccess: (blob, filename) => {
          setIsDownloading(false);
          fileDownload(blob, filename);
        },
        onError: () => {
          setIsDownloading(false);
        },
      })
    );
  };

  const onDownloadMap = () => {
    onDownload({ ...queryParams, filetype: 1 });
  };

  const onDownloadRequest = () => {
    onDownload({ ...queryParams, filetype: 2 });
  };

  return (
    <React.Fragment>
      <div className="btn-dropdown-area !z-auto" onMouseLeave={onCloseDropdown}>
        <Button className="flex items-center justify-center btn-dropdown" onClick={onOpenDropdown}>
          <img src="./img/icon-download.svg" className="mr-2 w-[16px]" />
          {t("output_file")}
        </Button>
        <div className={`${isDownloading ? "loading-small loading-small-download" : ""}`} />
        {isDropdownOpen ? (
          <ul className="dropdown-menu z-50">
            <li onClick={onDownloadMap}>
              <span>
                <img src="./img/icon_xls.svg" alt="download-position-map" className="icon-file" />
              </span>
              <span>{t("machine_layout_diagram")}</span>
            </li>
            {canExportRequest ? (
              <li onClick={onDownloadRequest}>
                <span>
                  <img src="./img/icon_xls.svg" alt="download-position-map" className="icon-file" />
                </span>
                <span>{t("machine_request_form")}</span>
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default FileExport;
