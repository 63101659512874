import moment from "moment";

import * as constants from "@/constants";
import environments from "@/environments";
import { DownloadFileRequest } from "@/sx-layout/components/plotmap/components/fileExport/models";

export const downloadPlotMapFile = ({ onBegin, onSuccess, onError, ...params }: DownloadFileRequest) => {
  const queryParams = new URLSearchParams({
    ...params,
    layout_date: moment(params.layout_date).format("YYYY-MM-DD"),
  } as Record<string, any>).toString();
  const endpoint = `${environments.API_BASE_URI}/layout/files?${queryParams}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        if (onBegin !== undefined) {
          onBegin();
        }

        return {
          type: constants.LAYOUT_FILE_START_DOWNLOAD,
        };
      },
      success: (response, filename) => {
        if (onSuccess !== undefined) {
          onSuccess(response, filename);
        }

        return {
          type: constants.LAYOUT_FILE_END_DOWNLOAD,
          payload: response,
        };
      },
      error: (response) => {
        if (onError !== undefined) {
          onError();
        }

        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
