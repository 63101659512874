import * as constants from "@/constants";
import environments from "@/environments";
import {
  CreateKeepOutAreaRequest,
  UpdateKeepOutAreaRequest,
  CopyKeepOutAreasProps,
  DeleteKeepOutAreasProps,
  UpdateKeepOutAreasCoordinatesProps,
} from "@/sx-layout/components/plotmap/actions/types";

export const createKeepOutAreas = ({ params, onSuccess }: CreateKeepOutAreaRequest) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/create`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        if (onSuccess !== undefined) {
          onSuccess(response);
        }

        return {
          type: constants.PLOT_MAP_END_CREATE_KEEP_OUT_AREAS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateKeepOutAreas = ({ params, onSuccess }: UpdateKeepOutAreaRequest) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/update/${params.keepout_area_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        if (onSuccess !== undefined) {
          onSuccess(response);
        }

        return {
          type: constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const copyKeepOutAreas = (props: CopyKeepOutAreasProps) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/copy`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_COPY_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess();
        }

        return {
          type: constants.PLOT_MAP_END_COPY_KEEP_OUT_AREAS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const deleteKeepOutAreas = (props: DeleteKeepOutAreasProps) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/delete/${props.input.keepout_area_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_DELETE_KEEP_OUT_AREAS,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess();
        }

        return {
          type: constants.PLOT_MAP_END_DELETE_KEEP_OUT_AREAS,
          payload: {
            ...response,
            machine_id: props.input.machine_id,
            keepout_area_id: props.input.keepout_area_id,
          },
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateKeepOutAreasCoordinates = (props: UpdateKeepOutAreasCoordinatesProps) => {
  const endpoint = `${environments.API_BASE_URI}/keepout_areas/rect/update/${props.input.keepout_area_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(props.input),
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_COORDINATES,
        };
      },
      success: (response) => {
        if (props?.onSuccess !== undefined) {
          props.onSuccess(response);
        }

        return {
          type: constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_COORDINATES,
          params: props.input,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
